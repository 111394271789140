import { combineReducers } from 'redux';
import chatStore from './chatReducer';
import upiStore from './upiPaymentsReducer';
import popotoStore from './popotoReducer';
import appStore from './appReducer';
import chartStore from './chartReducer';
import blogStore from './blogReducer';
import kycFormStore from './kycFormReducer';
import ckycFormStore from './ckycFormReducer';
import adminStore from './adminReducer';

export default combineReducers({
  chatStore,
  appStore,
  popotoStore,
  upiStore,
  chartStore,
  blogStore,
  kycFormStore,
  ckycFormStore,
  adminStore
});
