import { get } from 'lodash';
import {
    GET_PAN_OCR_DATA_FAILED,
    GET_PAN_OCR_DATA_REQUEST,
    GET_PAN_OCR_DATA_SUCCESS,
    SET_CHAT,
    SET_PAN_IMG_SRC,
    UPDATE_PAN_DATA,
    UPDATE_CHEQUE_DATA,
    SET_PAN_UPLOAD_STATUS,
    SET_CHEQUE_IMG_SRC,
    SET_CHEQUE_UPLOAD_STATUS,
    GET_CHEQUE_OCR_DATA_REQUEST,
    GET_CHEQUE_OCR_DATA_SUCCESS,
    GET_CHEQUE_OCR_DATA_FAILED,
    SEND_PHONE_VERIFICATION_REQUEST,
    SEND_PHONE_VERIFICATION_SUCCESS,
    SEND_PHONE_VERIFICATION_FAILED,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    SEND_EMAIL_VERIFICATION_REQUEST,
    SEND_EMAIL_VERIFICATION_SUCCESS,
    SEND_EMAIL_VERIFICATION_FAILED,
    GET_QUESTIONS_LIST_REQUEST,
    GET_QUESTIONS_LIST_SUCCESS,
    GET_QUESTIONS_LIST_FAILED,
    PARSE_BANK_STATEMENT_REQUEST,
    PARSE_BANK_STATEMENT_VALIDATION,
    PARSE_BANK_STATEMENT_SUCCESS,
    PARSE_BANK_STATEMENT_FAILED,
    SET_BANK_STATEMENT_IMG_SRC,
    GET_VOICE_OTP_REQUEST,
    GET_VOICE_OTP_SUCCESS,
    GET_VOICE_OTP_FAILED,
    VERIFY_PAN_DATA_REQUEST,
    VERIFY_PAN_DATA_SUCCESS,
    VERIFY_PAN_DATA_FAILED,
    VERIFY_PAN_DATA_STATUS,
    INVALID_PAN_FORMAT,
    FETCH_KYC_REQUEST,
    FETCH_KYC_SUCCESS,
    FETCH_KYC_FAILED,
    SAVE_ANSWERS_REQUEST,
    SAVE_ANSWERS_SUCCESS,
    SAVE_ANSWERS_FAILED,
    GET_CHAT_REQUEST,
    GET_CHAT_SUCCESS,
    GET_CHAT_FAILED,
    PARSE_OFFLINE_KYC_REQUEST,
    PARSE_OFFLINE_KYC_SUCCESS,
    PARSE_OFFLINE_KYC_FAILED,
    USER_NOT_AVAILABLE,
    PARSE_OFFLINE_KYC_VALIDATION,
    GET_ECAN_MESSAGES,
    RESET_PAN,
    RESET_CHEQUE,
    TOKEN_EXPIRY,
    SET_RE_LOGIN_MODAL_STATUS,
    VERIFY_FACE_COMPARE_REQUEST,
    VERIFY_FACE_COMPARE_SUCCESS,
    FACES_DOES_NOT_MATCH,
    VERIFY_FACE_COMPARE_FAILED,
    RESELECT_PAN,
    RESELECT_CHEQUE,
    GET_USER_DETAILS_SUCCESS,
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_REQUEST,
    VERIFY_ECAN_EXISTS_REQUEST,
    VERIFY_ECAN_EXISTS_SUCCESS,
    ECAN_NOT_EXISTS,
    VERIFY_ECAN_EXISTS_FAILED,
    SET_PAYEZ_STATUS_DETAILS,
    GET_PAYEZ_STATUS_REQUEST,
    GET_PAYEZ_STATUS_SUCCESS,
    GET_PAYEZ_STATUS_FAILED,
    VERIFY_KYC_EXISTS_REQUEST,
    VERIFY_KYC_EXISTS_SUCCESS,
    KYC_NOT_EXISTS,
    VERIFY_KYC_EXISTS_FAILED,
    FETCH_KYC_EMAIL_REQUEST,
    FETCH_KYC_EMAIL_SUCCESS,
    FETCH_KYC_EMAIL_FAILED,
    SEND_OAUTH_EMAIL_REQUEST,
    SEND_OAUTH_EMAIL_SUCCESS,
    SEND_OAUTH_EMAIL_FAILED,
    UNSUPPORTED_AUTO_EMAIL,
    VERIFY_CDSL_DETAILS_REQUEST,
    VERIFY_CDSL_DETAILS_SUCCESS,
    VERIFY_CDSL_DETAILS_FAILED,
    GET_STATE_AND_CITY_REQUEST,
    GET_STATE_AND_CITY_SUCCESS,
    GET_STATE_AND_CITY_FAILED,
    SAVE_USER_DETAILS_REQUEST,
    SAVE_USER_DETAILS_SUCCESS,
    SAVE_USER_DETAILS_FAILED,
    SEND_USER_CITY_REQUEST,
    SEND_USER_CITY_SUCCESS,
    SEND_USER_CITY_FAILED,
    SET_USER_RECENT_MESSAGES_REQUEST,
    GET_USER_RECENT_MESSAGES_REQUEST,
    GET_USER_RECENT_MESSAGES_SUCCESS,
    GET_USER_RECENT_MESSAGES_FAILED,
    UPLOAD_PORTFOLIO_REQUEST,
    UPLOAD_PORTFOLIO_SUCCESS,
    UPLOAD_PORTFOLIO_FAILED,
    SET_DASHBOARD_VIEW,
    GET_PORTFOLIO_VIEW_REQUEST,
    GET_PORTFOLIO_VIEW_SUCCESS,
    GET_PORTFOLIO_VIEW_FAILED,
    SET_PORTFOLIO_SIP_REQUEST,
    SET_PORTFOLIO_SIP_SUCCESS,
    SET_PORTFOLIO_SIP_FAILED,
    SET_ASSET_ALLOCATION_VIEW,
    GET_ASSET_ALLOCATION_REQUEST,
    GET_ASSET_ALLOCATION_SUCCESS,
    GET_ASSET_ALLOCATION_FAILED,
    GET_PORTFOLIO_DISTRIBUTION_REQUEST,
    GET_PORTFOLIO_DISTRIBUTION_SUCCESS,
    GET_PORTFOLIO_DISTRIBUTION_FAILED,
    UPDATE_ASSET_ALLOCATION_REQUEST,
    UPDATE_ASSET_ALLOCATION_SUCCESS,
    UPDATE_ASSET_ALLOCATION_FAILED,
    SAVE_VIDEO_KYC_REQUEST,
    SAVE_VIDEO_KYC_SUCCESS,
    SAVE_VIDEO_KYC_FAILED,
    GET_ESIGN_XML_REQUEST,
    GET_ESIGN_XML_SUCCESS,
    GET_ESIGN_XML_FAILED,
    FETCH_ESIGN_DOCUMENT_REQUEST,
    FETCH_ESIGN_DOCUMENT_SUCCESS,
    FETCH_ESIGN_DOCUMENT_FAILED,
    SET_ESIGN_DOC_TYPE,
    GET_OMNIBUS_STATUS_REQUEST,
    GET_OMNIBUS_STATUS_SUCCESS,
    GET_OMNIBUS_STATUS_FAILED,
    SET_SKIP_PORTFOLIO_REQUEST,
    SET_SKIP_PORTFOLIO_SUCCESS,
    SET_SKIP_PORTFOLIO_FAILED,
    SET_SKIP_PORTFOLIO_VIEW,
    REBALANCE_ALLOCATION_REQUEST,
    REBALANCE_ALLOCATION_SUCCESS,
    REBALANCE_ALLOCATION_FAILED,
    SET_REDEMPTION_WARNING_VIEW,
    SET_BANK_ACCOUNT_MISMATCH,
    UPDATE_ASSET_DETAILS_DETAILS,
    SET_PURCHASE_ORDER,
    SET_SELL_GROUP_ORDER_NO,
    SET_BUY_GROUP_ORDER_DETAILS,
    ECAN_BANK_DETAILS_REQUEST,
    ECAN_BANK_DETAILS_SUCCESS,
    ECAN_BANK_DETAILS_FAILED,
    SET_REDEMPTION_STATUS,
    GET_MIN_INVESTMENT_REQUEST,
    GET_MIN_INVESTMENT_SUCCESS,
    GET_MIN_INVESTMENT_FAILED,
    GET_CKYC_USER_DETAILS_REQUEST,
    GET_CKYC_USER_DETAILS_SUCCESS,
    GET_CKYC_USER_DETAILS_FAILED,
    SAVE_CKYC_USER_DETAILS_REQUEST,
    SAVE_CKYC_USER_DETAILS_SUCCESS,
    SAVE_CKYC_USER_DETAILS_FAILED,
    GET_ADVISORY_CONTRACT_REQUEST,
    GET_ADVISORY_CONTRACT_SUCCESS,
    GET_ADVISORY_CONTRACT_FAILED,
    SET_CVLKRA_QUESTIONS_LIST,
    SET_UPDATE_KYC,
    SET_UPDATE_CKYC,
    SET_PURCHASE_PENDING_REQUEST,
    SET_PURCHASE_PENDING_SUCCESS,
    SET_PURCHASE_PENDING_FAILED,
    FETCH_RECOMMENDATIONS_REQUEST,
    FETCH_RECOMMENDATIONS_SUCCESS,
    FETCH_RECOMMENDATIONS_FAILED,
    UPDATE_ALLOCATION_ORDER_DETAILS_REQUEST,
    UPDATE_ALLOCATION_ORDER_DETAILS_SUCCESS,
    UPDATE_ALLOCATION_ORDER_DETAILS_FAILED,
    GET_CONSENT_STATUS_REQUEST,
    GET_CONSENT_STATUS_SUCCESS,
    GET_CONSENT_STATUS_FAILED,
    GET_HOLDINGS_CONSENT_REQUEST,
    GET_HOLDINGS_CONSENT_SUCCESS,
    GET_HOLDINGS_CONSENT_FAILED,
    SET_DISCLOSURE_MODAL_STATUS,
    GET_IB_DISCLOSURE_FORMS_REQUEST,
    GET_IB_DISCLOSURE_FORMS_SUCCESS,
    GET_IB_DISCLOSURE_FORMS_FAILED,
    SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST,
    SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS,
    SAVE_SIGNED_DISCLOSURE_FORMS_FAILED,
    GET_DOCUMENTS_FLAG_REQUEST,
    GET_DOCUMENTS_FLAG_SUCCESS,
    GET_DOCUMENTS_FLAG_FAILED,
    SAVE_IB_DETAILS_FORM_REQUEST,
    SAVE_IB_DETAILS_FORM_SUCCESS,
    SAVE_IB_DETAILS_FORM_FAILED,
    ADD_IB_DOCUMENTS_REQUEST,
    ADD_IB_DOCUMENTS_SUCCESS,
    ADD_IB_DOCUMENTS_FAILED,
    SET_DOCUMENTS_MODAL_STATUS,
    CREATE_IB_ACCOUNT_REQUEST,
    CREATE_IB_ACCOUNT_SUCCESS,
    CREATE_IB_ACCOUNT_FAILED,
    GET_IB_UPDATE_STATUS_REQUEST,
    GET_IB_UPDATE_STATUS_SUCCESS,
    GET_IB_UPDATE_STATUS_FAILED,
    SAVE_IB_UPDATE_STATUS_REQUEST,
    SAVE_IB_UPDATE_STATUS_SUCCESS,
    SAVE_IB_UPDATE_STATUS_FAILED,
    GET_IB_ACCOUNT_STATUS_REQUEST,
    GET_IB_ACCOUNT_STATUS_SUCCESS,
    GET_IB_ACCOUNT_STATUS_FAILED,
    GET_IB_USER_DETAILS_REQUEST,
    GET_IB_USER_DETAILS_SUCCESS,
    GET_IB_USER_DETAILS_FAILED,
    SEND_USER_MESSAGE_REQUEST,
    SEND_USER_MESSAGE_SUCCESS,
    SEND_USER_MESSAGE_FAILED,
    SET_USER_CUSTOM_MESSAGE,
    FETCH_ADVISORY_SIGNED_PDF_REQUEST,
    FETCH_ADVISORY_SIGNED_PDF_SUCCESS,
    FETCH_ADVISORY_SIGNED_PDF_FAILED,
    SEND_ADMIN_CLIENT_PHONE_VERIFICATION_REQUEST,
    SEND_ADMIN_CLIENT_PHONE_VERIFICATION_SUCCESS,
    SEND_ADMIN_CLIENT_PHONE_VERIFICATION_FAILED,
    VERIFY_ADMIN_OTP_SUCCESS,
    VERIFY_ADMIN_OTP_REQUEST,
    VERIFY_ADMIN_OTP_FAILED,
    FETCH_SECTOR_DISTRIBUTION_REQUEST,
    FETCH_SECTOR_DISTRIBUTION_SUCCESS,
    FETCH_SECTOR_DISTRIBUTION_FAILED,
    FETCH_PORTFOLIO_PDF_REQUEST,
    FETCH_PORTFOLIO_PDF_SUCCESS,
    FETCH_PORTFOLIO_PDF_FAILED,
    GET_PORTFOLIO_FUND_LIST_REQUEST,
    GET_PORTFOLIO_FUND_LIST_SUCCESS,
    GET_PORTFOLIO_FUND_LIST_FAILED,
    SET_ARBITRARY_REDEMPTION_ORDER_DETAILS,
    SET_ARBITRARY_PURCHASE_ORDER_DETAILS,
    FETCH_CVLKRA_DOCUMENT_REQUEST,
    FETCH_CVLKRA_DOCUMENT_SUCCESS,
    FETCH_CVLKRA_DOCUMENT_FAILED,
    FETCH_CKYC_DOCUMENT_REQUEST,
    FETCH_CKYC_DOCUMENT_SUCCESS,
    FETCH_CKYC_DOCUMENT_FAILED, 
} from '../Constants/actionTypes.constants';
import { getUserVerificationMessages } from "../Constants/chatFlow";
import {getFromLocalStorage} from "../utils";
import additionalQuestions from "./additionalQuestions"
export const initialStore = {
    isAdminView: false,
    chatData: [],
    recentMessages: [],
    chatOptions: [],
    chatContents: {},
    panSrcUrl: '',
    chequeSrcUrl: '',
    bankStatementSrcUrl:'',
    panUploaded: false,
    chequeUploaded: false,
    panSelected: false,
    chequeSelected: false,
    kycPhone: getFromLocalStorage('phoneNumber') ? getFromLocalStorage('phoneNumber') : '',
    kycEmail: '',
    userLoggedIn: false,
    userPanData: {},
    userChequeData: {},
    loading: false,
    error: false,
    chatbotReScroll: null,
    phoneOtp:'',
    voiceOtp: "",
    panverifyStatus: false,
    panVerifyData:[],
    fetchKycLoader:false,
    fetchKycStatus:'',
    questionsList:[],
    savedAnswers:[],
    offlineKycImgUrl:'',
    offlineKycMsg: '',
    ecanData:[],
    birthCity:'',
    userDetails: {},
    ecanDetails: {},
    bankDetailsSource: '',
    tokenExpiry: false,
    tokenModalActive: false,
    faceMatchData:[],
    kycUserEmailData :{},
    oauthUrl:'',
    emailSupported: false,
    ecanExists: null,
    payezStatusDetails: [],
    payezOrderDetails:[],
    payezLoader: false,
    cdslUserDetails:{},
    stateCityDetails: {},
    stateCityLoader:false,
    userKycDetails:{},
    kycExists:null,
    oauthData:[],
    dashboardViewActive: false,
    portfolioDetails: {},
    sipDetails: {},
    sipLoader:false,
    assetAllocationViewActive: false,
    allocationDetails: {},
    distributionDetails: {},
    updateAllocationDetails: {},
    videoKyc: '',
    esignXml: {},
    esignDocument: {},
    esignDocType: '',
    omninusStatus: {},
    paymentTokenExpiry: false,
    verificationHook: '',
    skipPortolio: false,
    skipPortfolioView: false,
    rebalanceAllocationDetails: {},
    redemptionWarningView: false,
    bankAccountMismatch: false,
    setPurchaseOrder: false,
    setSellGroupOrderNo:'',
    setBuyGroupOrderDetails:{},
    ecanBankDetails:{},
    redemptionStatus:true,
    minInvestMentDetails:{},
    ckycUserDetails:{},
    userMessage: '',
    userAttachment: '',
    userFilename: '',
    offlineKycData:{},
    advisoryContractPdfUrl: '',
    cvlkraQuestionsList: [],
    cvlkraGeneratedPdf: {},
    cvlkraEsignedPdf: {},
    advisoryEsignedPdf: {},
    ckycGeneratedPdf: {},
    ckycEsignedPdf: {},
    setUpdateKYC: false,
    setUpdateCKYC: false,
    purchasePending: false,
    orderBasket: {},
    transactionOrderDetails: {},
    consentStatus: {},
    holdingConsentDetails: {},
    updatedOrderDetails: [],
    consentPending: null,
    oauthPending: null,
    portfolioAvailable: null,
    disclosureModalStatus: false,
    documentsModalStatus: false,
    disclosureForms: {},
    savedDisclosureForms: {},
    documentsList: {},
    saveIBDetails: {},
    saveIBDocuments: {},
    createIbStatus: {},
    updateIBstatus: {},
    saveUpdateIBstatus: {},
    ibAccountStatus: {},
    ibUserDetails: {},
    ibFlag: null,
    userMessageSending: false,
    userMessageSent: false,
    advisoryEsigned: false,
    adminPhone: '',
    clientPhoneList: [],
    isAdmin: false,
    sectorDistributionDetails: {},
    portfolioPdf: {},
    portfolioFundList: {},
    portfolioRedeemFundList: {},
    redeemOrderDetails: {},
    updateIBDetails:'',
    ibUserFullName:'',
    entityList:[],
    ckycGeneratePdf: {},
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case SET_CHAT:
            return {
                ...state,
                chatData: action.data.chatData,
                chatOptions: action.data.chatOptions,
            };
        case SET_USER_RECENT_MESSAGES_REQUEST:
            return {
                ...state,
                recentMessages: action.data.recentMessages,
                // chatOptions: action.data.chatOptions,
            };
        case SET_USER_CUSTOM_MESSAGE:
            return {
                ...state,
                userMessage: action.data.message,
                userAttachment: action.data.attachment,
                userFilename: action.data.fileName,
            };
        case GET_USER_RECENT_MESSAGES_REQUEST:
            return {
                ...state,
                isAdminView: false,
            };
        case GET_USER_RECENT_MESSAGES_SUCCESS:
            const userVerificationStatusMessages = getUserVerificationMessages(
                state.chatContents, state.chatData, action.data.verificationHook, action.data.kycFlow, action.data.userData.portfolio_skipped, 
                action.data.userData.omnibus_status, action.data.kyc_exist, action.data.document_fetched, action.data.userData.order_details,
                action.data.kyc_update, action.data.ckyc_update, action.data.userData.consent_pending, action.data.userData.oauth_pending,
                action.data.userData.portfolio_available, action.data.payment_token_expired, action.data.userData.bank_account_details,
                action.data.userData.advisory_esigned, action.data.userData.ib_flag,
            );
            const userDetailsData = get(action.data.userData, 'user_details', {});
            return {
                ...state,
                isAdminView: action.data.isAdmin,
                chatData: [...state.chatData, ...userVerificationStatusMessages],
                chatOptions: userVerificationStatusMessages[userVerificationStatusMessages.length - 1].options,
                userLoggedIn: true,
                kycEmail: get(action.data.userData, 'email', ''),
                userPanData: get(action.data.userData, 'pan', {}),
                birthCity: get(action.data.userData, 'birth_city', ''),
                userChequeData: get(action.data.userData, 'bank_account_details', {}),
                ecanDetails: get(action.data.userData, 'ecan_details', {}),
                userDetails: Object.keys(userDetailsData).length === 0 ? {...state.userDetails} : {...userDetailsData},
                questionsList: get(action.data.userData, 'user_risk_profile', []),
                savedAnswers: get(action.data.userData, 'user_risk_profile', []).map(x => {
                    return { question_id: x.question_id, answer_id: x.answers.find(y => y.selected).answer_id }
                }),
                userRiskScore: get(action.data.userData, 'user_risk_score', 5),
                portfolioDetails: get(action.data.userData, 'portfolio_details', {}),
                paymentTokenExpiry: get(action.data, 'payment_token_expired', false),
                verificationHook: get(action.data, 'verificationHook', ''),
                allocationDetails: get(action.data.userData, 'allocation_weights', {}),
                distributionDetails: get(action.data.userData, 'portfolio_weights', {}),
                skipPortfolioView: get(action.data.userData, 'portfolio_skipped', false),
                omninusStatus: get(action.data.userData, 'omnibus_status', {}),
                userKycDetails: get(action.data.userData, 'kyc_component_checkpoint', []),
                ckycUserDetails: get(action.data.userData, 'ckyc_component_checkpoint', {}),
                // cvlkraEsignedPdf: get(action.data.userData, 'cvlkra_esigned', {}),
                // cvlkraGeneratedPdf: get(action.data.userData, 'cvlkra_generated', {}),
                // ckycGeneratedPdf: get(action.data.userData, 'ckyc_generated', {}),
                // ckycEsignedPdf: get(action.data.userData, 'ckyc_esigned', {}),
                esignDocType: get(action.data, 'doc_type', ''),
                setUpdateKYC: get(action.data, 'update_kyc', false),
                setUpdateCKYC: get(action.data, 'update_ckyc', false),
                transactionOrderDetails: get(action.data.userData, 'order_details', {}),
                purchasePending: get(action.data.userData, 'order_details.purchase_pending', false),
                consentPending: get(action.data.userData, 'consent_pending', null),
                oauthPending: get(action.data.userData, 'oauth_pending', null),
                portfolioAvailable: get(action.data.userData, 'portfolio_available', null),
                ibFlag: get(action.data.userData, 'ib_flag', null),
                advisoryEsigned: get(action.data.userData, 'advisory_esigned', false),
            };
        case GET_USER_RECENT_MESSAGES_FAILED:
            return {
                ...state,
                isAdminView: false,
            };
        case GET_CHAT_REQUEST:
            return {
                ...state,
                chatContents: {},
            };
        case GET_CHAT_SUCCESS:
            return {
                ...state,
                chatContents: action.data.data,
                entityList: get(action.data, 'entity_list', []),
            };
        case GET_CHAT_FAILED:
            return {
                ...state,
                chatContents: {},
            };
        case SEND_ADMIN_CLIENT_PHONE_VERIFICATION_REQUEST:
            return {
                ...state,
            };
        case SEND_ADMIN_CLIENT_PHONE_VERIFICATION_SUCCESS:
            const updatedClientList = [...state.clientPhoneList, action.data.clientPhone];
            return {
                ...state,
                adminPhone: action.data.adminPhone,
                clientPhoneList: updatedClientList,
            };
        case SEND_ADMIN_CLIENT_PHONE_VERIFICATION_FAILED:
            return {
                ...state,
            };
        case SEND_USER_MESSAGE_REQUEST:
            return {
                ...state,
                userMessageSending: true,
                userMessage: action.data.message,
                userAttachment: action.data.attachment,
                userFilename: action.data.fileName,
                userMessageSent: false,
            };
        case SEND_USER_MESSAGE_SUCCESS:
            return {
                ...state,
                userMessageSending: false,
                userMessage: '',
                userAttachment: '',
                userFilename: '',
                userMessageSent: true,
            };
        case SEND_USER_MESSAGE_FAILED:
            return {
                ...state,
                userMessageSending: false,
                userMessageSent: false,
            };
        case SET_PAN_IMG_SRC:
            return {
                ...state,
                panSrcUrl: action.url,
            };
        case SET_PAN_UPLOAD_STATUS:
            return {
                ...state,
                panUploaded: action.flag,
            };
        case RESET_PAN:
            return {
                ...state,
                panSelected: action.flag,
            };
        case RESET_CHEQUE:
            return {
                ...state,
                chequeSelected: action.flag,
            };
        case SET_DISCLOSURE_MODAL_STATUS:
            return {
                ...state,
                disclosureModalStatus: action.flag,
            };
        case SET_DOCUMENTS_MODAL_STATUS:
            return {
                ...state,
                documentsModalStatus: action.flag,
            };
        case SET_CHEQUE_IMG_SRC:
            return {
                ...state,
                chequeSrcUrl: action.url,
            };
        case SET_CHEQUE_UPLOAD_STATUS:
            return {
                ...state,
                chequeUploaded: action.flag,
            };
        case SEND_PHONE_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case SEND_PHONE_VERIFICATION_SUCCESS:
            return {
                ...state,
                kycPhone: action.phone,
                phoneOtp:'',
                loading: false,
                error: false,
            };
        case SEND_PHONE_VERIFICATION_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_PAN_OCR_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                userPanData: {},
                error: false,
            };
        case GET_PAN_OCR_DATA_SUCCESS:
            return {
                ...state,
                userPanData: action.data,
                panSelected: true,
                loading: false,
                error: false,
            };
        case GET_PAN_OCR_DATA_FAILED:
            return {
                ...state,
                userPanData: {},
                loading: false,
                error: true,
            };
        case RESELECT_PAN:
            const clonedChatPan = JSON.parse(JSON.stringify(state.chatData));
            clonedChatPan.splice(clonedChatPan.length-2,2)
            return {
                ...state,
                panUploaded: false,
                chatData: clonedChatPan,
                chatOptions: clonedChatPan[clonedChatPan.length-1].options,
            };
        case RESELECT_CHEQUE:
            const clonedChatCheque = JSON.parse(JSON.stringify(state.chatData));
            clonedChatCheque.splice(clonedChatCheque.length-2,2)
            return {
                ...state,
                chequeUploaded: false,
                chatData: clonedChatCheque,
                chatOptions: clonedChatCheque[clonedChatCheque.length-1].options,
            };
        case GET_CHEQUE_OCR_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                bankDetailsSource: '',
                userChequeData: {},
                error: false,
            };
        case GET_CHEQUE_OCR_DATA_SUCCESS:
            return {
                ...state,
                userChequeData: action.data,
                chequeSelected: true,
                bankDetailsSource: 'cheque',
                loading: false,
                error: false,
            };
        case GET_CHEQUE_OCR_DATA_FAILED:
            return {
                ...state,
                bankDetailsSource: '',
                userChequeData: {},
                loading: false,
                error: true,
            };
        case GET_VOICE_OTP_REQUEST:
            return {
                ...state,
                loading: true,
                voiceOtp: "",
                error: false,
            };
        case GET_VOICE_OTP_SUCCESS:
            return {
                ...state,
                voiceOtp: action.data.text,
                loading: false,
                error: false,
            };
        case GET_VOICE_OTP_FAILED:
            return {
                ...state,
                voiceOtp: null,
                loading: false,
                error: true,
            };
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                phoneOtp:'',
                userDetails: {},
                error: false,
                isAdmin: false
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                phoneOtp:action.phoneOtp,
                userDetails: action.data.user_details,
                tokenExpiry: false,
                error: false,
                isAdmin: action.isAdmin
            };
        case VERIFY_OTP_FAILED:
            return {
                ...state,
                phoneOtp: '',
                userDetails: {},
                error: true,
                isAdmin: false
            };
        case VERIFY_ADMIN_OTP_REQUEST:
            return {
                ...state,
                phoneOtp:'',
                error: false,
            };
        case VERIFY_ADMIN_OTP_SUCCESS:
            return {
                ...state,
                phoneOtp:action.phoneOtp,
                kycPhone:action.clientPhoneNo,
                tokenExpiry: false,
                error: false,
            };
        case VERIFY_ADMIN_OTP_FAILED:
            return {
                ...state,
                phoneOtp: '',
                error: true,
            };
        case GET_USER_DETAILS_REQUEST:
            return {
                ...state,
                userDetails: {},
                error: false,
            };
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetails: {...state.userDetails, ...action.data},
                error: false,
            };
        case GET_USER_DETAILS_FAILED:
            return {
                ...state,
                userDetails: {},
                error: true,
            };
        case SEND_EMAIL_VERIFICATION_REQUEST:
            return {
                ...state,
                kycEmail:'',
                error: false,
            };
        case SEND_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                kycEmail:action.email,
                error: false,
            };
        case SEND_EMAIL_VERIFICATION_FAILED:
            return {
                ...state,
                kycEmail: '',
                error: true,
            };
        case UPDATE_PAN_DATA:
            return {
                ...state,
                userPanData: action.panData,
            };
        case UPDATE_CHEQUE_DATA:
            return {
                ...state,
                userChequeData: action.chequeData,
            };
        case VERIFY_PAN_DATA_REQUEST:
            return {
                ...state,
                panverifyStatus: true,
                panVerifyData: [],
                error: false,
            };
        case VERIFY_PAN_DATA_SUCCESS:
            return {
                ...state,
                panverifyStatus: false,
                panVerifyData: action.data,
                error: false,
            };
        case INVALID_PAN_FORMAT:
            return {
                ...state,
                panverifyStatus: false,
                panVerifyData: action.data,
                error: false,
            };
        case VERIFY_PAN_DATA_STATUS:
            return {
                ...state,
                panverifyStatus: false,
                panVerifyData: action.data,
                error: false,
            };
        case VERIFY_PAN_DATA_FAILED:
            return {
                ...state,
                panverifyStatus: false,
                panVerifyData: [],
                error: true,
            };
        case FETCH_KYC_REQUEST:
            return {
                ...state,
                fetchKycLoader:true,
                fetchKycStatus:'',
                error: false,
            };
        case FETCH_KYC_SUCCESS:
            return {
                ...state,
                fetchKycLoader:false,
                fetchKycStatus: action.data,
                error: false,
            };
        case FETCH_KYC_FAILED:
            return {
                ...state,
                fetchKycLoader:false,
                fetchKycStatus:'',
            };
        case GET_QUESTIONS_LIST_REQUEST:
            return {
                ...state,
                questionsList: [],
                error: false,
            };
        case GET_QUESTIONS_LIST_SUCCESS:
            return {
                ...state,
                questionsList: [...action.data.questions,...additionalQuestions],
                error: false,
            };
        case GET_QUESTIONS_LIST_FAILED:
            return {
                ...state,
                questionsList: [],
                error: true,
            };
        case SAVE_ANSWERS_REQUEST:
            return {
                ...state,
                savedAnswers: [],
                error: false,
            };
        case SAVE_ANSWERS_SUCCESS:
            return {
                ...state,
                savedAnswers: action.answer_list,
                error: false,
            };
        case SAVE_ANSWERS_FAILED:
            return {
                ...state,
                savedAnswers: [],
                error: true,
            };
        case PARSE_BANK_STATEMENT_REQUEST:
            return {
                ...state,
                userChequeData: [],
                error: false,
                bankDetailsSource: '',
            };
        case PARSE_BANK_STATEMENT_VALIDATION:
            return {
                ...state,
                userChequeData: action.data,
                bankDetailsSource: '',
                error: false,
            };
        case PARSE_BANK_STATEMENT_SUCCESS:
            return {
                ...state,
                userChequeData: action.data,
                bankDetailsSource: 'bank-statement',
                error: false,
            };
        case PARSE_BANK_STATEMENT_FAILED:
            return {
                ...state,
                userChequeData: [],
                bankDetailsSource: '',
                error: true,
            };
        case SET_BANK_STATEMENT_IMG_SRC:
            return {
                ...state,
                bankStatementSrcUrl: action.url,
            };
        case PARSE_OFFLINE_KYC_REQUEST:
            return {
                ...state,
                offlineKycImgUrl: '',
                error: false,
            };
        case PARSE_OFFLINE_KYC_SUCCESS:
            return {
                ...state,
                offlineKycImgUrl: action.data.image_64,
                offlineKycData: action.data,
                error: false,
            };
        case USER_NOT_AVAILABLE:
            return {
                ...state,
                offlineKycMsg: action.data.message,
                error: true,
            };
        case PARSE_OFFLINE_KYC_VALIDATION:
            return {
                ...state,
                offlineKycMsg: action.data.message,
                error: true,
            };
        case PARSE_OFFLINE_KYC_FAILED:
            return {
                ...state,
                offlineKycImgUrl: '',
                error: true,
            };
        case GET_ECAN_MESSAGES:
            return {
                ...state,
                ecanData: action.data,
            };
        case SEND_USER_CITY_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case SEND_USER_CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                birthCity: action.city,
            };
        case SEND_USER_CITY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case TOKEN_EXPIRY:
            return {
                ...state,
                tokenExpiry: true,
                error: false,
                tokenModalActive: true,
            };
        case SET_RE_LOGIN_MODAL_STATUS:
            return {
                ...state,
                tokenModalActive: action.data,
            };
        case VERIFY_FACE_COMPARE_REQUEST:
            return {
                ...state,
                faceMatchData:[],
                error: false,
            };
        case VERIFY_FACE_COMPARE_SUCCESS:
            return {
                ...state,
                faceMatchData: action.data,
                error: false,
            };
        case FACES_DOES_NOT_MATCH:
            return {
                ...state,
                faceMatchData: action.data,
                error: false,
            };
        case VERIFY_FACE_COMPARE_FAILED:
            return {
                ...state,
                faceMatchData: [],
                error: true,
            };
        case FETCH_KYC_EMAIL_REQUEST:
            return {
                ...state,
                kycUserEmailData :{},
                error: false,
            };
        case FETCH_KYC_EMAIL_SUCCESS:
            return {
                ...state,
                kycUserEmailData : action.data,
                error: false,
                emailSupported: true,
            };
        case UNSUPPORTED_AUTO_EMAIL:
            return {
                ...state,
                kycUserEmailData : action.data,
                error: true,
                emailSupported: false,
            };
        case FETCH_KYC_EMAIL_FAILED:
            return {
                ...state,
                kycUserEmailData : {},
                error: true,
            };
        case SEND_OAUTH_EMAIL_REQUEST:
            return {
                ...state,
                oauthUrl:'',
                error: false,
            };
        case SEND_OAUTH_EMAIL_SUCCESS:
            return {
                ...state,
                oauthData: action.data,
                oauthUrl: action.data.authorization_url,
                error: false,
            };
        case SEND_OAUTH_EMAIL_FAILED:
            return {
                ...state,
                oauthUrl: '',
                error: true,
            };
        case VERIFY_ECAN_EXISTS_REQUEST:
            return {
                ...state,
                error: false,
                ecanExists: null,
            };
        case VERIFY_ECAN_EXISTS_SUCCESS:
            return {
                ...state,
                ecanExists: true,
                error: false,
            };
        case ECAN_NOT_EXISTS:
            return {
                ...state,
                ecanExists: false,
                error: false,
            };
        case VERIFY_ECAN_EXISTS_FAILED:
            return {
                ...state,
                ecanExists: null,
                error: true,
            };
        case SET_PAYEZ_STATUS_DETAILS:
            return {
                ...state,
                payezStatusDetails: action.data,
            };
        case SET_ARBITRARY_REDEMPTION_ORDER_DETAILS:
            return {
                ...state,
                redeemOrderDetails: action.data,
                updatedOrderDetails: action.data.fundList,
            };
        case SET_ARBITRARY_PURCHASE_ORDER_DETAILS:
            return {
                ...state,
                purchaseOrderDetails: action.data,
                updatedOrderDetails: action.data.fundList,
            };
        case SET_CVLKRA_QUESTIONS_LIST:
            return {
                ...state,
                cvlkraQuestionsList: action.questionsList,
            };
        case SET_ESIGN_DOC_TYPE:
            return {
                ...state,
                esignDocType: action.data.docType,
            };
        case GET_PAYEZ_STATUS_REQUEST:
            return {
                ...state,
                payezLoader: true,
                error: false,
            };
        case GET_PAYEZ_STATUS_SUCCESS:
            return {
                ...state,
                payezLoader: false,
                payezOrderDetails: action.data,
                error: false,
            };
        case GET_PAYEZ_STATUS_FAILED:
            return {
                ...state,
                payezLoader: false,
                error: true,
            };
        case VERIFY_CDSL_DETAILS_REQUEST:
            return {
                ...state,
                cdslUserDetails: {},
                error: false,
            };
        case VERIFY_CDSL_DETAILS_SUCCESS:
            return {
                ...state,
                cdslUserDetails: action.data,
                error: false,
            };
        case VERIFY_CDSL_DETAILS_FAILED:
            return {
                ...state,
                cdslUserDetails: {},
                error: true,
            };
        case GET_STATE_AND_CITY_REQUEST:
            return {
                ...state,
                stateCityLoader: true,
                error: false,
            };
        case GET_STATE_AND_CITY_SUCCESS:
            return {
                ...state,
                stateCityLoader: false,
                stateCityDetails: action.data,
                error: false,
            };
        case GET_STATE_AND_CITY_FAILED:
            return {
                ...state,
                stateCityLoader: false,
                error: true,
            };
        case SAVE_USER_DETAILS_REQUEST:
            return {
                ...state,
                userKycDetails: {},
                error: false,
            };
        case SAVE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                stateCityLoader: false,
                userKycDetails: action.data,
                error: false,
            };
        case SAVE_USER_DETAILS_FAILED:
            return {
                ...state,
                userKycDetails: {},
                error: true,
            };
        case VERIFY_KYC_EXISTS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case VERIFY_KYC_EXISTS_SUCCESS:
            return {
                ...state,
                kycExists: true,
                error: false,
            };
        case KYC_NOT_EXISTS:
            return {
                ...state,
                kycExists: false,
                error: false,
            };
        case VERIFY_KYC_EXISTS_FAILED:
            return {
                ...state,
                kycExists: null,
                error: true,
            };
        case UPLOAD_PORTFOLIO_REQUEST:
            return {
                ...state, 
                error: false,
            };
        case UPLOAD_PORTFOLIO_SUCCESS:
            return {
                ...state,
                error: false,
            };
        case UPLOAD_PORTFOLIO_FAILED:
            return {
                ...state,
                error: true,
            };
        case SET_DASHBOARD_VIEW:
            return {
                ...state,
                dashboardViewActive: action.flag,
            };
        case SET_SKIP_PORTFOLIO_VIEW:
            return {
                ...state,
                skipPortfolioView: action.flag,
            };
        case SET_REDEMPTION_WARNING_VIEW:
            return {
                ...state,
                redemptionWarningView: action.flag,
            };
        case SET_BANK_ACCOUNT_MISMATCH:
            return {
                ...state,
                bankAccountMismatch: action.flag,
            };
        case SET_PURCHASE_ORDER:
            return {
                ...state,
                setPurchaseOrder: action.flag,
            };
        case SET_SELL_GROUP_ORDER_NO:
            return {
                ...state,
                setSellGroupOrderNo: action.orderNo,
            };
        case SET_REDEMPTION_STATUS:
            return {
                ...state,
                redemptionStatus: action.status,
            };
        case SET_BUY_GROUP_ORDER_DETAILS:
            return {
                ...state,
                setBuyGroupOrderDetails: action.buyDetails,
            };
        case GET_PORTFOLIO_VIEW_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_PORTFOLIO_VIEW_SUCCESS:
            return {
                ...state,
                portfolioDetails: action.data,
                error: false,
            };
        case GET_PORTFOLIO_VIEW_FAILED:
            return {
                ...state,
                portfolioDetails: {},
                error: true,
            };
        case SET_PORTFOLIO_SIP_REQUEST:
            return {
                ...state,
                error: false,
                sipLoader: true,
            };
        case SET_PORTFOLIO_SIP_SUCCESS:
            return {
                ...state,
                sipDetails: action.data,
                error: false,
                sipLoader: false,
            };
        case SET_PORTFOLIO_SIP_FAILED:
            return {
                ...state,
                sipDetails: {},
                error: true,
                sipLoader: false,
            };
        case SET_ASSET_ALLOCATION_VIEW:
            return {
                ...state,
                assetAllocationViewActive: action.flag,
            };
        case GET_ASSET_ALLOCATION_REQUEST:
            return {
                ...state,
                error: false,
                allocationDetails: {},
            };
        case GET_ASSET_ALLOCATION_SUCCESS:
            return {
                ...state,
                allocationDetails: action.data,
                error: false,
            };
        case GET_ASSET_ALLOCATION_FAILED:
            return {
                ...state,
                allocationDetails: {},
                error: true,
            };
        case GET_PORTFOLIO_DISTRIBUTION_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_PORTFOLIO_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                distributionDetails: action.data,
                error: false,
            };
        case GET_PORTFOLIO_DISTRIBUTION_FAILED:
            return {
                ...state,
                distributionDetails: {},
                error: true,
            };
        case UPDATE_ASSET_ALLOCATION_REQUEST:
            return {
                ...state,
                error: false,
            };
        case UPDATE_ASSET_ALLOCATION_SUCCESS:
            return {
                ...state,
                error: false,
            };
        case UPDATE_ASSET_ALLOCATION_FAILED:
            return {
                ...state,
                error: true,
            };
        case UPDATE_ASSET_DETAILS_DETAILS:
            return {
                ...state,
                updateAllocationDetails: action.updateDetails,
            };
        case SAVE_VIDEO_KYC_REQUEST:
            return {
                ...state,
                error: false,
                videoKyc: '',
            };
        case SAVE_VIDEO_KYC_SUCCESS:
            return {
                ...state,
                videoKyc: action.data,
                error: false,
            };
        case SAVE_VIDEO_KYC_FAILED:
            return {
                ...state,
                videoKyc: '',
                error: true,
            };
        case GET_ESIGN_XML_REQUEST:
            return {
                ...state,
                error: false,
                esignXml: {},
            };
        case GET_ESIGN_XML_SUCCESS:
            return {
                ...state,
                esignXml: action.data,
                error: false,
            };
        case GET_ESIGN_XML_FAILED:
            return {
                ...state,
                esignXml: {},
                error: true,
            };
        case FETCH_ESIGN_DOCUMENT_REQUEST:
            return {
                ...state,
                error: false,
                esignDocument: {},
            };
        case FETCH_ESIGN_DOCUMENT_SUCCESS:
            return {
                ...state,
                esignDocument: action.data,
                error: false,
            };
        case FETCH_ESIGN_DOCUMENT_FAILED:
            return {
                ...state,
                esignDocument: {},
                error: true,
            };
        case GET_OMNIBUS_STATUS_REQUEST:
            return {
                ...state,
                error: false,
                omninusStatus: {},
            };
        case GET_OMNIBUS_STATUS_SUCCESS:
            return {
                ...state,
                omninusStatus: action.data,
                error: false,
            };
        case GET_OMNIBUS_STATUS_FAILED:
            return {
                ...state,
                omninusStatus: {},
                error: true,
            };
        case SET_SKIP_PORTFOLIO_REQUEST:
            return {
                ...state,
                error: false,
            };
        case SET_SKIP_PORTFOLIO_SUCCESS:
            return {
                ...state,
                skipPortolio: action.data.success,
                error: false,
            };
        case SET_SKIP_PORTFOLIO_FAILED:
            return {
                ...state,
                error: true,
            };
        case REBALANCE_ALLOCATION_REQUEST:
            return {
                ...state,
                error: false,
            };
        case REBALANCE_ALLOCATION_SUCCESS:
            return {
                ...state,
                rebalanceAllocationDetails: action.data,
                error: false,
            };
        case REBALANCE_ALLOCATION_FAILED:
            return {
                ...state,
                error: true,
            };
        case ECAN_BANK_DETAILS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case ECAN_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                ecanBankDetails: action.data,
                error: false,
            };
        case ECAN_BANK_DETAILS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_MIN_INVESTMENT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_MIN_INVESTMENT_SUCCESS:
            return {
                ...state,
                minInvestMentDetails: action.data,
                error: false,
            };
        case GET_MIN_INVESTMENT_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_CKYC_USER_DETAILS_REQUEST:
            return {
                ...state,
                error: false,
                ckycUserDetails: {},
            };
        case GET_CKYC_USER_DETAILS_SUCCESS:
            return {
                ...state,
                ckycUserDetails: action.data,
                error: false,
            };
        case GET_CKYC_USER_DETAILS_FAILED:
            return {
                ...state,
                ckycUserDetails: {},
                error: true,
            };
        case SAVE_CKYC_USER_DETAILS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case SAVE_CKYC_USER_DETAILS_SUCCESS:
            return {
                ...state,
                ckycUserDetails: action.userData,
                error: false,
            };
        case SAVE_CKYC_USER_DETAILS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_ADVISORY_CONTRACT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_ADVISORY_CONTRACT_SUCCESS:
            return {
                ...state,
                advisoryContractPdfUrl: action.data,
                error: false,
            };
        case GET_ADVISORY_CONTRACT_FAILED:
            return {
                ...state,
                error: true,
            };
        case SET_UPDATE_KYC:
            return {
                ...state,
                setUpdateKYC: action.flag,
            };
        case SET_UPDATE_CKYC:
            return {
                ...state,
                setUpdateCKYC: action.flag,
            };
        case SET_PURCHASE_PENDING_REQUEST:
            return {
                ...state,
                error: false,
            };
        case SET_PURCHASE_PENDING_SUCCESS:
            return {
                ...state,
                purchasePending: action.data,
                error: false,
            };
        case SET_PURCHASE_PENDING_FAILED:
            return {
                ...state,
                error: true,
            };
        case FETCH_RECOMMENDATIONS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_RECOMMENDATIONS_SUCCESS:
            return {
                ...state,
                orderBasket: action.data,
                error: false,
            };
        case FETCH_RECOMMENDATIONS_FAILED:
            return {
                ...state,
                error: true,
            };
        case UPDATE_ALLOCATION_ORDER_DETAILS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case UPDATE_ALLOCATION_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                updatedOrderDetails: action.data,
                error: false,
            };
        case UPDATE_ALLOCATION_ORDER_DETAILS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_CONSENT_STATUS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_CONSENT_STATUS_SUCCESS:
            return {
                ...state,
                consentStatus: action.data,
                error: false,
            };
        case GET_CONSENT_STATUS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_HOLDINGS_CONSENT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_HOLDINGS_CONSENT_SUCCESS:
            return {
                ...state,
                holdingConsentDetails: action.data,
                error: false,
            };
        case GET_HOLDINGS_CONSENT_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_IB_DISCLOSURE_FORMS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_IB_DISCLOSURE_FORMS_SUCCESS:
            return {
                ...state,
                disclosureForms: action.data,
                error: false,
            };
        case GET_IB_DISCLOSURE_FORMS_FAILED:
            return {
                ...state,
                error: true,
            };
        case SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS:
            return {
                ...state,
                savedDisclosureForms: action.data,
                error: false,
            };
        case SAVE_SIGNED_DISCLOSURE_FORMS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_DOCUMENTS_FLAG_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_DOCUMENTS_FLAG_SUCCESS:
            return {
                ...state,
                documentsList: action.data,
                error: false,
            };
        case GET_DOCUMENTS_FLAG_FAILED:
            return {
                ...state,
                error: true,
            };
        case SAVE_IB_DETAILS_FORM_REQUEST:
            return {
                ...state,
                error: false,
            };
        case SAVE_IB_DETAILS_FORM_SUCCESS:
            return {
                ...state,
                saveIBDetails: action.data,
                ibUserFullName: action.fullName,
                error: false,
            };
        case SAVE_IB_DETAILS_FORM_FAILED:
            return {
                ...state,
                error: true,
            };
        case ADD_IB_DOCUMENTS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case ADD_IB_DOCUMENTS_SUCCESS:
            return {
                ...state,
                saveIBDocuments: action.data,
                error: false,
            };
        case ADD_IB_DOCUMENTS_FAILED:
            return {
                ...state,
                error: true,
            };
        case CREATE_IB_ACCOUNT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case CREATE_IB_ACCOUNT_SUCCESS:
            return {
                ...state,
                //createIbStatus: action.data,
                createIbStatus: {
                    'success': true
                },
                error: false,
            };
        case CREATE_IB_ACCOUNT_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_IB_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_IB_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                updateIBstatus: action.data,
                error: false,
            };
        case GET_IB_UPDATE_STATUS_FAILED:
            return {
                ...state,
                error: true,
            };
        case SAVE_IB_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case SAVE_IB_UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                updateIBDetails:'success',
                saveUpdateIBstatus: action.data,
                error: false,
            };
        case SAVE_IB_UPDATE_STATUS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_IB_ACCOUNT_STATUS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_IB_ACCOUNT_STATUS_SUCCESS:
            return {
                ...state,
                ibAccountStatus: action.data,
                error: false,
            };
        case GET_IB_ACCOUNT_STATUS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_IB_USER_DETAILS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_IB_USER_DETAILS_SUCCESS:
            return {
                ...state,
                ibUserDetails: action.data,
                error: false,
            };
        case GET_IB_USER_DETAILS_FAILED:
            return {
                ...state,
                error: true,
            };
        case FETCH_ADVISORY_SIGNED_PDF_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_ADVISORY_SIGNED_PDF_SUCCESS:
            return {
                ...state,
                advisoryEsignedPdf: action.data,
                error: false,
            };
        case FETCH_ADVISORY_SIGNED_PDF_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_PORTFOLIO_FUND_LIST_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_PORTFOLIO_FUND_LIST_SUCCESS:
            return {
                ...state,
                portfolioFundList: get(action.data, 'purchase', {}),
                portfolioRedeemFundList:  get(action.data, 'redeem', {}),
                error: false,
            };
        case GET_PORTFOLIO_FUND_LIST_FAILED:
            return {
                ...state,
                error: true,
            };
        case FETCH_SECTOR_DISTRIBUTION_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_SECTOR_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                sectorDistributionDetails: action.data,
                error: false,
            };
        case FETCH_SECTOR_DISTRIBUTION_FAILED:
            return {
                ...state,
                error: true,
            };
        case FETCH_PORTFOLIO_PDF_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_PORTFOLIO_PDF_SUCCESS:
            return {
                ...state,
                portfolioPdf: action.data,
                error: false,
            };
        case FETCH_PORTFOLIO_PDF_FAILED:
            return {
                ...state,
                error: true,
            };
        case FETCH_CVLKRA_DOCUMENT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_CVLKRA_DOCUMENT_SUCCESS:
            return {
                ...state,
                cvlkraGeneratedPdf: action.data,
                error: false,
            };
        case FETCH_CVLKRA_DOCUMENT_FAILED:
            return {
                ...state,
                error: true,
            };
        case FETCH_CKYC_DOCUMENT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_CKYC_DOCUMENT_SUCCESS:
            return {
                ...state,
                ckycGeneratePdf: action.data,
                error: false,
            };
        case FETCH_CKYC_DOCUMENT_FAILED:
            return {
                ...state,
                error: true,
            };
        default:
            return state
    }
}
