import { startsWith, capitalize } from 'lodash';
import {bugAlert } from '../Actions/bugAlert';
export const clientMessageMapping = {
    yes: 'client-yes',
    submit: 'client-submit',
    uploadPan: 'client-upload pan',
    confirm: 'client-confirm',
    upload: 'client-upload',
    omnibusFreeMfAccount: 'client-Omnibus free MF Account',
    tokenPayment: 'client-Make token payment',
    yourRiskProfile: 'client-Your risk profile',
    yesLetsStart: "client-Yes, let's start",
    uploadPortfolio: 'client-Upload Portfolio',
    assetAllocation: 'client-Asset Allocation',
    renewPayment: 'client-Renew payment',
    skipAllocation: 'client-Skip Portfolio',
    recurringPaymentMandate: 'client-Recurring Payment Mandate',
    offlineKyc: 'client-Offline KYC',
    userDetailsForm: 'client-User Details Form',
    generateKYCForm: 'client-Generate KYC Form',
    downloadKYCForm: 'client-Download KYC Form',
    eSign: 'client-eSign',
    accept: 'client-Accept',
    downloadeSignDocument: 'client-Download eSign Document',
    submitKYCForm: 'client-Submit KYC Form',
    generateCKYCform: 'client-Generate CKYC form',
    proceed: 'client-Proceed',
    downloadCKYC: 'client-Download CKYC',
    proceedwithdigilocker: 'client-Proceed with digilocker',
    linktodigilocker: 'client-Link to digilocker',
    fetchfromdigilocker: 'client-Fetch from digilocker',
    userKycDetailsForm: 'client-User Details Form',
    rebalancePortfolio: 'client-Rebalance Portfolio',
    redeem: 'client-Redeem',
    buy: 'client-Complete Purchase',
    purchase: 'client-Purchase',
    updateKyc: 'client-Update KYC',
    submitCkycForm: 'client-Submit CKYC Form',
    linkPortfolio: 'client-Link Portfolio',
    enableAutoConfirmation: 'client-Enable Auto Confirmation',
    viewDashboard: 'client-View Dashboard',
}

// kyc not exists -- offline kyc

export const kycFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'ckycFormGenerate', 'initiateCKYCSubmit', clientMessageMapping.downloadCKYC, 'downloadCKYCForm', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', 
    clientMessageMapping.omnibusFreeMfAccount, 'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];
// kyc not exists -- offline kyc --- advisory_esigned: true

export const kycAndAdvisoryEsignFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'ckycFormGenerate', 'initiateCKYCSubmit', clientMessageMapping.downloadCKYC, 'downloadCKYCForm', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];

// kyc not exists -- offline kyc --- skip ckyc

export const kycOfflineAndSkipCKYCFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];

// kyc not exists -- offline kyc --- skip ckyc --- advisory_esigned: true

export const offlineForSkipCKYCAndAdvisoryEsignFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];

// kyc  exists -- update kyc - offline kyc
export const updateKycFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadUpdateKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'CKYCFormGenerateSuccess', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];
// kyc  exists -- update kyc - offline kyc --- advisory_esigned: true
export const updateKycAndAdvisoryEsignedFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadUpdateKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'CKYCFormGenerateSuccess', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];

// kyc  exists -- update kyc - offline kyc - skip ckyc
export const skipCKYCOfflineFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'KYCFormSubmitSuccess', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];
// kyc  exists -- update kyc - offline kyc - skip ckyc --- advisory_esigned: true
export const skipCKYCOfflineAndAdvisoryEsignedFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.offlineKyc, 'offlineKyc', clientMessageMapping.submit,
    'offlineKycSuccess', clientMessageMapping.userKycDetailsForm, 'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'KYCFormSubmitSuccess', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];

// kyc not exists -- digilocker
export const kycFlowDigilockerNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'ckycFormGenerate', 'initiateCKYCSubmit', clientMessageMapping.downloadCKYC, 'downloadCKYCForm', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];
// kyc not exists -- digilocker --- advisory_esigned: true
export const kycFlowDigilockerAndAdvisoryEsignNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'ckycFormGenerate', 'initiateCKYCSubmit', clientMessageMapping.downloadCKYC, 'downloadCKYCForm', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];
// kyc not exists -- digilocker --- skip ckyc
export const kycDigilockerAndSkipCKYCFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];
// kyc not exists -- digilocker --- skip ckyc --- advisory_esigned: true
export const digilockerForSkipCKYCAndAdvisoryEsignFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'kycNotAvailable', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'initiateSubmit', clientMessageMapping.downloadKYCForm, 'downloadKycForm', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadKycFormOption', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
];

// kyc  exists -- update kyc - digilocker
export const updateKycFlowDigilockerNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadUpdateKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'CKYCFormGenerateSuccess', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];
// kyc  exists -- update kyc - digilocker --- advisory_esigned: true
export const updateKycFlowForDigilockerAndAdvisoryEsignedNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'downloadUpdateKycFormOption', clientMessageMapping.generateCKYCform, 'ckycFormGenerateMsg', clientMessageMapping.proceed,'cKycDetailsForm', clientMessageMapping.submit,
    'cKycDetailsFormSuccess', clientMessageMapping.proceed, 'CKYCFormGenerateSuccess', clientMessageMapping.eSign, 
    'esignedCKYCFormSuccess', clientMessageMapping.submitCkycForm, 'submitCKYCForm', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];

// kyc  exists -- update kyc - digilocker - skip ckyc
export const skipCKycFlowDigilockerNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'KYCFormSubmitSuccess', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];
// kyc  exists -- update kyc - digilocker - skip ckyc --- advisory_esigned: true
export const skipCKycFlowForDigilockerAndAdvisoryEsignedNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.updateKyc, 'updateKYC', clientMessageMapping.proceedwithdigilocker, 'digilockerAccountCheck', clientMessageMapping.yes,
    'digilockerConnect', clientMessageMapping.linktodigilocker, 'digilockerLinkAccount', clientMessageMapping.fetchfromdigilocker, 'digilockerSuccess', clientMessageMapping.proceed,
    'userKycDetailsForm', clientMessageMapping.submit, 'userKycDetailsFormSuccess', clientMessageMapping.generateKYCForm, 
    'KYCFormGenerateSuccess', clientMessageMapping.eSign, 'esignedKYCFormSuccess', clientMessageMapping.submitKYCForm,
    'KYCFormSubmitSuccess', clientMessageMapping.upload, 'chequeOcrReceived', clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 
    'ecanCancelledDetails', 'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    // 'upiTransactionSuccess', clientMessageMapping.uploadPortfolio, 'portfolioAlreadyUploaded', clientMessageMapping.submit, 'dashboardView',
    // clientMessageMapping.assetAllocation, 'assetAllocation',
];

// kyc exists 
export const eCanFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.yes, 'fetchKYCLoad',
    'fetchKycSuccess', clientMessageMapping.submit, 'uploadCheque', clientMessageMapping.upload, 'chequeOcrReceived',
    clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 'ecanCancelledDetails', 
    'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    
];
// kyc exists --- advisory_esigned: true
export const eCanAndAdvisoryEsignedFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.yes, 'fetchKYCLoad',
    'fetchKycSuccess', clientMessageMapping.submit, 'uploadCheque', clientMessageMapping.upload, 'chequeOcrReceived',
    clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 'ecanCancelledDetails', 
    'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment,'upiPayment', clientMessageMapping.submit,
    
];

//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : false -> oauth_pending: false -> portfolio exists : true
export const portfolioUploadFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIb', clientMessageMapping.rebalancePortfolio, 
    'transactionRedeemSummaryForIb'
];
//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : false -> oauth_pending: false -> portfolio exists : true
export const portfolioUploadForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocation', clientMessageMapping.rebalancePortfolio, 
    'transactionRedeemSummary'
];

//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : true -> oauth_pending: false -> portfolio exists : true
export const mandateExistsAndConsentPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndConsentPending', clientMessageMapping.rebalancePortfolio, 
    'redeemSummaryForConsentPendingForIb'
];
//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : true -> oauth_pending: false -> portfolio exists : true
export const mandateExistsAndConsentPendingForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndConsentPending', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForConsentPending', clientMessageMapping.rebalancePortfolio, 
    'redeemSummaryForConsentPending'
];

//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : false -> oauth_pending: true -> portfolio exists : true
export const mandateExistsAndOauthPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved',
    clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndOauthPending', clientMessageMapping.rebalancePortfolio, 
    'redeemSummaryForOauthPendingForIb'
];
//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : false -> oauth_pending: true -> portfolio exists : true
export const mandateExistsAndOauthPendingForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndOauthPending', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved',
    clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForOauthPending', clientMessageMapping.rebalancePortfolio, 
    'redeemSummaryForOauthPending'
];

//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : true -> oauth_pending: true -> portfolio exists : true
export const mandateExistsAndConsentOauthPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndConsentOauthPending', clientMessageMapping.rebalancePortfolio, 
    'consentAndOAuthPendingForMandateForIb'
];
//  funds redeemed and purchase_pending : true - mandate exists -> consent_pending : true -> oauth_pending: true -> portfolio exists : true
export const mandateExistsAndConsentOauthPendingForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreation', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForConsentAndOauthPending', clientMessageMapping.rebalancePortfolio, 
    'consentAndOAuthPendingForMandate'
];


// --- funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: false -> portfolio exists : true
export const mandateExistsFlowNodeList = [
    'successUpiTransactionForNoPendingConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIb', clientMessageMapping.rebalancePortfolio, 
    'transactionMandateExistsSummaryForIb'
];
// --- funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: false -> portfolio exists : true
export const mandateExistsForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocation', clientMessageMapping.rebalancePortfolio, 
    'transactionMandateExistsSummary'
];

//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: false -> portfolio exists : true
export const mandateAndConsentPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndConsentPending', clientMessageMapping.rebalancePortfolio, 
    'summaryForConsentPendingAndManadateForIb'
];
//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: false -> portfolio exists : true
export const mandateAndConsentPendingForIbCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndConsentPending', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForConsentPending', clientMessageMapping.rebalancePortfolio, 
    'summaryForConsentPendingAndManadate'
];

//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: true -> portfolio exists : true
export const mandateAndOauthPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved',
    clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndOauthPending', clientMessageMapping.rebalancePortfolio, 
    'summaryForOauthPendingAndMandateForIb'
];
//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: true -> portfolio exists : true
export const mandateAndOauthPendingForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndOauthPending', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved',
    clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForOauthPending', clientMessageMapping.rebalancePortfolio, 
    'summaryForOauthPendingAndMandate'
];

//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: true -> portfolio exists : true
export const mandateAndConsentOauthPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndConsentOauthPending', clientMessageMapping.rebalancePortfolio, 
    'consentAndOAuthPendingForMandateExistsForIb'
];
//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: true -> portfolio exists : true
export const mandateAndConsentOauthPendingForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreation', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForConsentAndOauthPending', clientMessageMapping.rebalancePortfolio, 
    'consentAndOAuthPendingForMandateExists'
];


// --- funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: false -> portfolio exists : false
export const mandateExistsAndSkipPortfolioFlowNodeList = [
    'successUpiTransactionForNoPendingConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.assetAllocation, 'skipPortfolioForIb', clientMessageMapping.purchase, 
    'transactionPortfolioNotExistsSummaryForIb'
];
// --- funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: false -> portfolio exists : false
export const mandateExistsAndSkipPortfolioFlowForIbCreationNodeList = [
    'successUpiTransactionForIBCreationAndConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.assetAllocation, 'skipPortfolio', clientMessageMapping.purchase, 
    'transactionPortfolioNotExistsSummary'
];

//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: false -> portfolio exists : false
export const skipPortfolioAndConsentPendingFlowNodeList = [
    'successUpiTransactionForConsentPending', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.assetAllocation, 'skipPortfolioForIbAndConsentPending', clientMessageMapping.purchase, 
    'summaryForConsentPendingAndSkipPortfolioForIb'
];
//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: false -> portfolio exists : false
export const skipPortfolioAndConsentPendingFlowForIbCreationNodeList = [
    'successUpiTransactionForIBCreationAndConsentPending', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.assetAllocation, 'skipPortfolioForConsentPending', clientMessageMapping.purchase, 
    'summaryForConsentPendingAndSkipPortfolio'
];

//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: true -> portfolio exists : false
export const skipPortfolioAndOauthPendingFlowNodeList = [
    'successUpiTransactionForOauthPending', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved',
    clientMessageMapping.assetAllocation, 'skipPortfolioForIbAndOauthPending', clientMessageMapping.purchase, 'summaryForOauthPendingAndSkipPortfolioForIb'
];
//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : false -> oauth_pending: true -> portfolio exists : false
export const skipPortfolioAndOauthPendingForIbCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndOauthPending', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved',
    clientMessageMapping.assetAllocation, 'skipPortfolioForOauthPending', clientMessageMapping.purchase, 'summaryForOauthPendingAndSkipPortfolio'
];

//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: true -> portfolio exists : false
export const consentOauthPendingAndMandateExistsFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.assetAllocation, 'skipPortfolioForIbAndConsentOauthPending', clientMessageMapping.purchase, 
    'consentAndOAuthPendingForSkipPortfolioForIb'
];
//  funds redeemed and purchase_pending : false - mandate exists -> consent_pending : true -> oauth_pending: true -> portfolio exists : false
export const consentOauthPendingAndMandateExistsForIbCreationFlowNodeList = [
    'successUpiTransactionForIBCreation', clientMessageMapping.assetAllocation, 'skipPortfolioForConsentAndOauthPending', clientMessageMapping.purchase, 
    'consentAndOAuthPendingForSkipPortfolio'
];



//portfolio Uploaded, funds redeemed and purchased
export const portfolioUploadandRebalanceFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.uploadPortfolio, 'uploadPortfolio', clientMessageMapping.submit, 'dashboardView',
    clientMessageMapping.assetAllocation, 'assetAllocation', clientMessageMapping.rebalancePortfolio, 
    'transactionSummary', clientMessageMapping.recurringPaymentMandate,
];

// Mandate not Exists - purchase pending - true
export const portfolioUploadMandateNotExistsFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.assetAllocation, 'assetAllocation', clientMessageMapping.rebalancePortfolio, 
    'transactionRedeemSummary', clientMessageMapping.purchase, 'transactionSummary', clientMessageMapping.recurringPaymentMandate,
];

// Mandate not Exists,  purchasePending: false, skip Portfolio -> consent_pending : false -> oauth_pending: false  -> portfolio exists : true
export const portfolioUploadMandateNotExistsRebalanceFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocationForIb', clientMessageMapping.rebalancePortfolio, 'transactionSummaryForPortfolioExists'
];
// Mandate not Exists,  purchasePending: false, skip Portfolio -> consent_pending : false -> oauth_pending: false  -> portfolio exists : true
export const portfolioUploadMandateNotExistsForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocation', clientMessageMapping.rebalancePortfolio, 'transactionSummaryForPortfolioExists'
];

//  Mandate not Exists,  purchasePending: false, skip Portfolio -> consent_pending : false -> oauth_pending: false  -> portfolio exists : false
export const MandateAndPortfolioNotExistsNodeList = [
    'successUpiTransactionForNoPendingConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.assetAllocation,  
    'skipPortfolioForIb', clientMessageMapping.purchase, 'summaryForPortfolioNotExists'
];
//  Mandate not Exists,  purchasePending: false, skip Portfolio -> consent_pending : false -> oauth_pending: false  -> portfolio exists : false
export const MandateAndPortfolioNotExistsForIbCreationNodeList = [
    'successUpiTransactionForIBCreationAndConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.assetAllocation,  
    'skipPortfolio', clientMessageMapping.purchase, 'summaryForPortfolioNotExists'
];

//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : true, portfolo_exists: true
export const mandateNotExistsConsentandOauthPendingNodeList = [
    'successUpiTransaction', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocationForIbAndConsentOauthPending', clientMessageMapping.rebalancePortfolio, 'SummaryForConsentAndOAuthPending',
];
//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : true, portfolo_exists: true
export const mandateNotExistsConsentandOauthPendingForIbCreationNodeList = [
    'successUpiTransactionForIBCreation', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocationForConsentAndOauthPending', clientMessageMapping.rebalancePortfolio, 'SummaryForConsentAndOAuthPending',
];

//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : true, portfolo_exists: false
export const consentandOauthPendingForNoPortfolioNodeList = [
    'successUpiTransaction', clientMessageMapping.assetAllocation,  
    'skipPortfolioForConsentAndOauthPending', clientMessageMapping.purchase, 'consentAndOAuthPendingSummary',
];
//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : true, portfolo_exists: false
export const consentandOauthPendingForNoPortfolioAndIbCreationNodeList = [
    'successUpiTransactionForIBCreation', clientMessageMapping.assetAllocation,  
    'skipPortfolioForConsentAndOauthPending', clientMessageMapping.purchase, 'consentAndOAuthPendingSummary',
];

//  Mandate not Exists,  purchasePending: false, consent_pending : false , oauth_pending : true, portfolo_exists: true
export const mandateNotExistsOauthPendingNodeList = [
    'successUpiTransaction', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocationForIbAndOauthPending', clientMessageMapping.rebalancePortfolio, 'transactionSummaryForOauthPending',
];
//  Mandate not Exists,  purchasePending: false, consent_pending : false , oauth_pending : true, portfolo_exists: true
export const mandateNotExistsOauthPendingForIbCreationNodeList = [
    'successUpiTransactionForIBCreationAndOauthPending', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocationForOauthPending', clientMessageMapping.rebalancePortfolio, 'transactionSummaryForOauthPending',
];

//  Mandate not Exists,  purchasePending: false, consent_pending : false , oauth_pending : true, portfolo_exists: false
export const oauthPendingForNoPortfolioNodeList = [
    'successUpiTransactionForOauthPending', clientMessageMapping.assetAllocation,  
    'skipPortfolioForIbAndOauthPending', clientMessageMapping.purchase, 'summaryForOauthPending',
];
//  Mandate not Exists,  purchasePending: false, consent_pending : false , oauth_pending : true, portfolo_exists: false
export const oauthPendingForNoPortfolioAndIbCreationNodeList = [
    'successUpiTransactionForIBCreationAndOauthAndConsentPending',
    'skipPortfolioForOauthPending', clientMessageMapping.purchase, 'summaryForOauthPending',
];

//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : false, portfolo_exists: true
export const mandateNotExistsConsentPendingNodeList = [
    'successUpiTransaction', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocationForIbAndConsentPending', clientMessageMapping.rebalancePortfolio, 'transactionSummaryForConsentPending',
];
//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : false, portfolo_exists: true
export const mandateNotExistsConsentPendingForIbCreationNodeList = [
    'successUpiTransactionForIBCreationAndConsentPending', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation,  
    'assetAllocationForConsentPending', clientMessageMapping.rebalancePortfolio, 'transactionSummaryForConsentPending',
];

//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : false, portfolio_exists: false
export const consentPendingForNoPortfolioNodeList = [
    'successUpiTransactionForConsentPending', clientMessageMapping.assetAllocation,  
    'skipPortfolioForIbAndConsentPending', clientMessageMapping.purchase, 'summaryForConsentPending',
];
//  Mandate not Exists,  purchasePending: false, consent_pending : true , oauth_pending : false, portfolio_exists: false
export const consentPendingForNoPortfolioAndIbCreationNodeList = [
    'successUpiTransactionForIBCreationAndConsentPending', clientMessageMapping.assetAllocation,  
    'skipPortfolioForConsentPending', clientMessageMapping.purchase, 'summaryForConsentPending',
];

//  Mandate not Exists, purchase_pending : true - consent_pending : false -> oauth_pending: false -> portfolio exists : true
export const MandateNotExistsFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIb', clientMessageMapping.rebalancePortfolio, 
    'transactionRedeemSummaryForIb'
];
//  Mandate not Exists, purchase_pending : true - consent_pending : false -> oauth_pending: false -> portfolio exists : true
export const MandateNotExistsForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndConsentAndOauth', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocation', clientMessageMapping.rebalancePortfolio, 
    'transactionRedeemSummary'
];

//  Mandate not Exists, purchase_pending : true - consent_pending : true -> oauth_pending: false -> portfolio exists : true
export const mandateNotExistsAndConsentPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndConsentPending', clientMessageMapping.rebalancePortfolio, 
    'redeemSummaryForNoMandateConsentPending'
];
//  Mandate not Exists, purchase_pending : true - consent_pending : true -> oauth_pending: false -> portfolio exists : true
export const mandateNotExistsAndConsentPendingForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndConsentPending', clientMessageMapping.enableAutoConfirmation,
    'enableAutoConfirmationSuccess', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForConsentPending', clientMessageMapping.rebalancePortfolio, 
    'redeemSummaryForNoMandateConsentPending'
];

//  Mandate not Exists, purchase_pending : true - consent_pending : false -> oauth_pending: true -> portfolio exists : true
export const mandateNotExistsAndOauthPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', 
     clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndOauthPending', 
     clientMessageMapping.rebalancePortfolio, 'redeemSummaryForMandateNotExistsOauthPending'
];
//  Mandate not Exists, purchase_pending : true - consent_pending : false -> oauth_pending: true -> portfolio exists : true
export const mandateNotExistsAndOauthPendingForIbCreationFlowNodeList = [
    'successUpiTransactionForIBCreationAndOauthPending', clientMessageMapping.linkPortfolio, 'portfolioConsentApproved', 
     clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForOauthPending', 
     clientMessageMapping.rebalancePortfolio, 'redeemSummaryForMandateNotExistsOauthPending'
];

//  Mandate not Exists, purchase_pending : true - consent_pending : true -> oauth_pending: true -> portfolio exists : true
export const mandateNotExistsAndConsentOauthPendingFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForIbAndConsentOauthPending', clientMessageMapping.rebalancePortfolio, 
    'consentAndOAuthPendingForNoMandate'
];
//  Mandate not Exists, purchase_pending : true - consent_pending : true -> oauth_pending: true -> portfolio exists : true
export const mandateNotExistsAndConsentOauthPendingForIBCreationFlowNodeList = [
    'successUpiTransactionForIBCreation', clientMessageMapping.viewDashboard, 'dashboardView', clientMessageMapping.assetAllocation, 'assetAllocationForConsentAndOauthPending', clientMessageMapping.rebalancePortfolio, 
    'consentAndOAuthPendingForNoMandate'
];

// skip Portfolio
export const skipPortfolioFlowNodeList = [
    'successUpiTransaction', clientMessageMapping.skipAllocation, 'skipPortfolio', clientMessageMapping.purchase,
    'transactionSummary', clientMessageMapping.recurringPaymentMandate,
];


// Ecan Not Approved
export const ecanNotApprovedFlowNodeList = [
    'transactionSuccess', 'ecanNotApproved',
];

// advisory_esigned : false, paymentExpiry
export const paymentExpiryFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.yes, 'fetchKYCLoad',
    'fetchKycSuccess', clientMessageMapping.submit, 'uploadCheque', clientMessageMapping.upload, 'chequeOcrReceived',
    clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 'ecanCancelledDetails', 
    'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'advisoryContractSuccess', clientMessageMapping.tokenPayment, 'paymentExpiry', clientMessageMapping.renewPayment,
    'upiPayment', clientMessageMapping.submit
];

// advisory_esigned : true, paymentExpiry
export const paymentExpiryForEsignedAdvisoryFlowNodeList = [
    'emailVerification', clientMessageMapping.submit, 'emailVerificationSent', clientMessageMapping.uploadPan,
    'panOcrReceived', clientMessageMapping.confirm, 'panDataVerify', clientMessageMapping.yes, 'fetchKYCLoad',
    'fetchKycSuccess', clientMessageMapping.submit, 'uploadCheque', clientMessageMapping.upload, 'chequeOcrReceived',
    clientMessageMapping.confirm, 'accountVerified', clientMessageMapping.omnibusFreeMfAccount, 'ecanCancelledDetails', 
    'ecanExists', clientMessageMapping.yourRiskProfile, 'questionsStartMsg', clientMessageMapping.yesLetsStart,
    'questionsList', clientMessageMapping.submit, 'riskProfileChart', 'advisoryContract',
    'downloadAdvisorySignDocument', clientMessageMapping.tokenPayment, 'paymentExpiry', clientMessageMapping.renewPayment,
    'upiPayment', clientMessageMapping.submit
];

export const checkpointMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'panDataVerify',
    'kyc_fetched': 'fetchKycSuccess',
    'birth_city_added': 'uploadCheque',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    //'risk_profile_created': 'riskProfileChart',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}

export const checkpointKYCNotExistsMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'offline_kyc_uploaded': 'offlineKycSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'downloadKycForm',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'downloadKycFormOption',
    'ckyc_component_checkpoint': 'cKycDetailsFormSuccess',
    'ckyc_generated': 'downloadCKYCForm',
    'ckyc_esigned' : 'esignedCKYCFormSuccess',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}

export const checkpointKYCNotExistsAndSkipCKYCMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'offline_kyc_uploaded': 'offlineKycSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'downloadKycForm',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'downloadKycFormOption',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}

export const checkpointUpdateKYCOfflineMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'offline_kyc_uploaded': 'offlineKycSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'KYCFormGenerateSuccess',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'downloadUpdateKycFormOption',
    'ckyc_component_checkpoint': 'cKycDetailsFormSuccess',
    'ckyc_generated': 'CKYCFormGenerateSuccess',
    'ckyc_esigned' : 'esignedCKYCFormSuccess',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}

export const checkpointSkipCKYCOfflineMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'offline_kyc_uploaded': 'offlineKycSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'KYCFormGenerateSuccess',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'KYCFormSubmitSuccess',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}

export const checkpointSkipCKYCDigilockerMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'digilocker_fetched': 'digilockerSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'KYCFormGenerateSuccess',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'KYCFormSubmitSuccess',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}

export const checkpointUpdateKYCDigilockerMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'digilocker_fetched': 'digilockerSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'KYCFormGenerateSuccess',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'downloadUpdateKycFormOption',
    'ckyc_component_checkpoint': 'cKycDetailsFormSuccess',
    'ckyc_generated': 'CKYCFormGenerateSuccess',
    'ckyc_esigned' : 'esignedCKYCFormSuccess',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}

export const checkpointDigilockerMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'digilocker_fetched': 'digilockerSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'downloadKycForm',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'downloadKycFormOption',
    'ckyc_component_checkpoint': 'cKycDetailsFormSuccess',
    'ckyc_generated': 'downloadCKYCForm',
    'ckyc_esigned' : 'esignedCKYCFormSuccess',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}
export const checkpointDigilockerFlowForKycNotExistsAndSkipCKYCMapping = {
    'phone_verified': 'emailVerification',
    'email_verified': 'emailVerificationSent',
    'pan_verified': 'panOcrReceived',
    'kyc_registered': 'kycNotAvailable',
    'digilocker_fetched': 'digilockerSuccess',
    'kyc_component_checkpoint': 'userKycDetailsFormSuccess',
    'cvlkra_generated': 'downloadKycForm',
    'cvlkra_esigned': 'esignedKYCFormSuccess',
    'kyc_submitted': 'downloadKycFormOption',
    'bank_account_verified': 'accountVerified',
    'ecan_exists': 'ecanExists',
    'ecan_cancelled': 'ecanCancelledDetails',
    'risk_profile_created': 'advisoryContract',
    'token_amount_paid': 'successUpiTransaction',
    // 'portfolio_uploaded' : 'dashboardView',
    'portfolio_allocated' : 'assetAllocation',
    'portfolio_rebalanced' : 'transactionSummary',
}





export const getUserVerificationMessages = (chatContents, chatData, verificationHook, kycFlow, skipPortolio, omnibusStatus, kycExists, documentsFetched, orderDetils, updateKyc, updateCKYC, consentPending, oauthPending, portfolioAvailable, paymentTokenExpiry,bankAccountDetails, advisoryEsigned, ibFlag) => {
    const userMessages = [];
    const ibFlagStatus = ibFlag === null ? true : ibFlag.start === true ? -1 : 0 ;
    const purchasePending = orderDetils ? orderDetils.purchase_pending : false;
    const ecanApproved = omnibusStatus ? omnibusStatus.ecan_approved : null;
    const mandateExists = omnibusStatus ? omnibusStatus.mandate_exists : null;
    const paymentExpiryCondition = (verificationHook === 'token_amount_paid' || verificationHook === 'portfolio_allocated') && paymentTokenExpiry === true;
    const messageKYCBreakpoint = kycExists ? (updateKyc ? (updateCKYC ?
        (documentsFetched === 'offline_kyc' ?
            checkpointUpdateKYCOfflineMapping[verificationHook] : (checkpointUpdateKYCDigilockerMapping[verificationHook])) :(documentsFetched === 'offline_kyc' ? checkpointSkipCKYCOfflineMapping[verificationHook]
    : checkpointSkipCKYCDigilockerMapping[verificationHook])) 
    : checkpointMapping[verificationHook]) 
    : (documentsFetched === 'offline_kyc' ? (bankAccountDetails && (Object.keys(bankAccountDetails).length > 0) ? checkpointKYCNotExistsAndSkipCKYCMapping[verificationHook] : checkpointKYCNotExistsMapping[verificationHook])
    : (bankAccountDetails && (Object.keys(bankAccountDetails).length > 0) ? checkpointDigilockerFlowForKycNotExistsAndSkipCKYCMapping[verificationHook] : checkpointDigilockerMapping[verificationHook] ) );

    const messageBreakpoint = (verificationHook === 'token_amount_paid' && ecanApproved === false) ?  'transactionSuccess' : paymentExpiryCondition ? 'paymentExpiry' 
    : (verificationHook === 'risk_profile_created' && advisoryEsigned === true) ? 'downloadAdvisorySignDocument' 
    : (verificationHook === 'risk_profile_created' && advisoryEsigned === false) ? 'advisoryContract' 

    // : (verificationHook === 'token_amount_paid' && portfolioAvailable === true && !consentPending && !oauthPending) ? 
    // 'successUpiTransactionForNoConsentAndOauthPending'
    // : (verificationHook === 'token_amount_paid' && portfolioAvailable === true && !consentPending && oauthPending) ? 
    // 'successUpiTransactionForOauthPending'
    // : (verificationHook === 'token_amount_paid' && portfolioAvailable === false && consentPending && !oauthPending) ? 
    // 'successUpiTransactionForConsentPending'
    // : (verificationHook === 'token_amount_paid' && portfolioAvailable === false && consentPending && oauthPending) ? 
    // 'upiTransactionSuccess'

    : (verificationHook === 'token_amount_paid' && portfolioAvailable === true && !consentPending && !oauthPending && ibFlagStatus !== -1) ? 
    'successUpiTransactionForNoPendingConsentAndOauth'
    : (verificationHook === 'token_amount_paid' && portfolioAvailable === true && !consentPending && oauthPending && ibFlagStatus !== -1) ? 
    'successUpiTransactionForOauthPending'
    : (verificationHook === 'token_amount_paid' && portfolioAvailable === false && consentPending && !oauthPending && ibFlagStatus !== -1) ? 
    'successUpiTransactionForConsentPending'
    : (verificationHook === 'token_amount_paid' && portfolioAvailable === false && consentPending && oauthPending && ibFlagStatus !== -1) ? 
    'successUpiTransaction'
    : verificationHook === 'token_amount_paid' && portfolioAvailable === false && !consentPending && oauthPending && ibFlagStatus === -1 ?
    'successUpiTransactionForIBCreationAndOauthAndConsentPending'
    : (verificationHook === 'token_amount_paid' && portfolioAvailable === true && !consentPending && !oauthPending && ibFlagStatus === -1) ? 
    'successUpiTransactionForIBCreationAndConsentAndOauth'
    : (verificationHook === 'token_amount_paid' && portfolioAvailable === true && !consentPending && oauthPending && ibFlagStatus === -1) ? 
    'successUpiTransactionForIBCreationAndOauthPending'
    : (verificationHook === 'token_amount_paid' && portfolioAvailable === false && consentPending && !oauthPending && ibFlagStatus === -1) ? 
    'successUpiTransactionForIBCreationAndConsentPending'
    : (verificationHook === 'token_amount_paid' && portfolioAvailable === false && consentPending && oauthPending && ibFlagStatus === -1) ? 
    'successUpiTransactionForIBCreation'

    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && !consentPending && !oauthPending && ibFlagStatus === -1) ? 
    'skipPortfolio'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && !consentPending && oauthPending && ibFlagStatus === -1) ? 
    'skipPortfolioForOauthPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && consentPending && !oauthPending && ibFlagStatus === -1) ? 
    'skipPortfolioForConsentPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && consentPending && oauthPending && ibFlagStatus === -1) ? 
    'skipPortfolioForConsentAndOauthPending'

    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && !consentPending && !oauthPending && ibFlagStatus !== -1) ? 
    'skipPortfolioForIb'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && !consentPending && oauthPending && ibFlagStatus !== -1) ? 
    'skipPortfolioForIbAndOauthPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && consentPending && !oauthPending && ibFlagStatus !== -1) ? 
    'skipPortfolioForIbAndConsentPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === false && consentPending && oauthPending && ibFlagStatus !== -1) ? 
    'skipPortfolioForIbAndConsentOauthPending'

    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && !consentPending && !oauthPending && ibFlagStatus === -1) ? 
    'assetAllocation'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && !consentPending && oauthPending && ibFlagStatus === -1) ? 
    'assetAllocationForOauthPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && consentPending && !oauthPending && ibFlagStatus === -1) ? 
    'assetAllocationForConsentPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && consentPending && oauthPending && ibFlagStatus === -1) ? 
    'assetAllocationForConsentAndOauthPending'

    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && !consentPending && !oauthPending && ibFlagStatus !== -1) ? 
    'assetAllocationForIb'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && !consentPending && oauthPending && ibFlagStatus !== -1) ? 
    'assetAllocationForIbAndOauthPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && consentPending && !oauthPending && ibFlagStatus !== -1) ? 
    'assetAllocationForIbAndConsentPending'
    : (verificationHook === 'portfolio_allocated' && portfolioAvailable === true && consentPending && oauthPending && ibFlagStatus !== -1) ? 
    'assetAllocationForIbAndConsentOauthPending'


    // : (purchasePending === false && !mandateExists && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    // 'transactionSummaryForPortfolioExists' 
    // : (purchasePending === false && !mandateExists && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    // 'transactionSummaryForOauthPending'
    // : (purchasePending === false && !mandateExists && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    // 'transactionSummaryForConsentPending'
    // : (purchasePending === false && !mandateExists && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    // 'SummaryForConsentAndOAuthPending'
    // : (purchasePending === false && !mandateExists && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    // 'summaryForPortfolioNotExists' 
    // : (purchasePending === false && !mandateExists && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    // 'summaryForOauthPending'
    // : (purchasePending === false && !mandateExists && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    // 'summaryForConsentPending'
    // : (purchasePending === false && !mandateExists && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    // 'consentAndOAuthPendingSummary'

    // : (purchasePending === true && !mandateExists && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    // 'transactionRedeemSummary' 
    // : (purchasePending === true && !mandateExists && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    // 'redeemSummaryForMandateNotExistsOauthPending'
    // : (purchasePending === true && !mandateExists && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    // 'redeemSummaryForNoMandateConsentPending'
    // : (purchasePending === true && !mandateExists && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    // 'consentAndOAuthPendingForNoMandate'

    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    'transactionMandateExistsSummary' 
    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    'summaryForOauthPendingAndMandate'
    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    'summaryForConsentPendingAndManadate'
    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    'consentAndOAuthPendingForMandateExists'
    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    'transactionPortfolioNotExistsSummary' 
    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    'summaryForOauthPendingAndSkipPortfolio'
    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    'summaryForConsentPendingAndSkipPortfolio'
    : (purchasePending === false && mandateExists && ibFlagStatus === -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    'consentAndOAuthPendingForSkipPortfolio'

    : (purchasePending === true && mandateExists && ibFlagStatus === -1 && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    'transactionRedeemSummary' 
    : (purchasePending === true && mandateExists && ibFlagStatus === -1 && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    'redeemSummaryForOauthPending'
    : (purchasePending === true && mandateExists && ibFlagStatus === -1 && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    'redeemSummaryForConsentPending'
    : (purchasePending === true && mandateExists && ibFlagStatus === -1 && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    'consentAndOAuthPendingForMandate'



    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    'transactionMandateExistsSummaryForIb' 
    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    'summaryForOauthPendingAndMandateForIb'
    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    'summaryForConsentPendingAndManadateForIb'
    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === true && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    'consentAndOAuthPendingForMandateExistsForIb'
    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    'transactionPortfolioNotExistsSummaryForIb' 
    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    'summaryForOauthPendingAndSkipPortfolioForIb'
    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    'summaryForConsentPendingAndSkipPortfolioForIb'
    : (purchasePending === false && mandateExists && ibFlagStatus !== -1 && portfolioAvailable === false && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    'consentAndOAuthPendingForSkipPortfolioForIb'

    : (purchasePending === true && mandateExists && ibFlagStatus !== -1 && verificationHook === 'portfolio_rebalanced' && !consentPending && !oauthPending) ? 
    'transactionRedeemSummaryForIb' 
    : (purchasePending === true && mandateExists && ibFlagStatus !== -1 && verificationHook === 'portfolio_rebalanced' && !consentPending && oauthPending) ? 
    'redeemSummaryForOauthPendingForIb'
    : (purchasePending === true && mandateExists && ibFlagStatus !== -1 && verificationHook === 'portfolio_rebalanced' && consentPending && !oauthPending) ? 
    'redeemSummaryForConsentPendingForIb'
    : (purchasePending === true && mandateExists && ibFlagStatus !== -1 && verificationHook === 'portfolio_rebalanced' && consentPending && oauthPending) ? 
    'consentAndOAuthPendingForMandateForIb'
    : messageKYCBreakpoint;

    const totalNodeList = (kycExists ? 
        (updateKyc ? 
            (updateCKYC ? 
                (documentsFetched === 'offline_kyc' ? (paymentExpiryCondition ?  (advisoryEsigned ? paymentExpiryForEsignedAdvisoryFlowNodeList : paymentExpiryFlowNodeList) : (advisoryEsigned ? updateKycAndAdvisoryEsignedFlowNodeList : updateKycFlowNodeList)) 
                : (paymentExpiryCondition ?  (advisoryEsigned ? paymentExpiryForEsignedAdvisoryFlowNodeList : paymentExpiryFlowNodeList) : (advisoryEsigned ?  updateKycFlowForDigilockerAndAdvisoryEsignedNodeList : updateKycFlowDigilockerNodeList)))
                : (documentsFetched === 'offline_kyc' ? (paymentExpiryCondition ?  (advisoryEsigned ? paymentExpiryForEsignedAdvisoryFlowNodeList : paymentExpiryFlowNodeList) : (advisoryEsigned ? skipCKYCOfflineAndAdvisoryEsignedFlowNodeList : skipCKYCOfflineFlowNodeList)) 
                : (paymentExpiryCondition ?  (advisoryEsigned ? paymentExpiryForEsignedAdvisoryFlowNodeList : paymentExpiryFlowNodeList) : (advisoryEsigned ? skipCKycFlowForDigilockerAndAdvisoryEsignedNodeList : skipCKycFlowDigilockerNodeList)))) 
                : (paymentExpiryCondition ?  (advisoryEsigned ? paymentExpiryForEsignedAdvisoryFlowNodeList : paymentExpiryFlowNodeList) : (advisoryEsigned ? eCanAndAdvisoryEsignedFlowNodeList : eCanFlowNodeList)))
                : (documentsFetched === 'offline_kyc' ? (paymentExpiryCondition ?  (advisoryEsigned ? paymentExpiryForEsignedAdvisoryFlowNodeList : paymentExpiryFlowNodeList) 
                : ((bankAccountDetails && Object.keys(bankAccountDetails).length > 0) ? (advisoryEsigned ? offlineForSkipCKYCAndAdvisoryEsignFlowNodeList : kycOfflineAndSkipCKYCFlowNodeList)
                : (advisoryEsigned ? kycAndAdvisoryEsignFlowNodeList : kycFlowNodeList))) 
                : (paymentExpiryCondition ?  (advisoryEsigned ? paymentExpiryForEsignedAdvisoryFlowNodeList : paymentExpiryFlowNodeList) 
                : ((bankAccountDetails && Object.keys(bankAccountDetails).length > 0) ? (advisoryEsigned ? digilockerForSkipCKYCAndAdvisoryEsignFlowNodeList : kycDigilockerAndSkipCKYCFlowNodeList)
                : (advisoryEsigned ? kycFlowDigilockerAndAdvisoryEsignNodeList : kycFlowDigilockerNodeList)))));

    const ecanNotApprovedList = totalNodeList.concat(ecanNotApprovedFlowNodeList);
    //purchase_pending: false, mandate not exists
    const consentAndOauthPendingList = totalNodeList.concat(mandateNotExistsConsentandOauthPendingNodeList);
    const consentAndOauthPendingForIbCreationList = totalNodeList.concat(mandateNotExistsConsentandOauthPendingForIbCreationNodeList);
    const consentPendingList = totalNodeList.concat(mandateNotExistsConsentPendingNodeList);
    const consentPendingListForIbCreation = totalNodeList.concat(mandateNotExistsConsentPendingForIbCreationNodeList);
    const oauthPendingList  = totalNodeList.concat(mandateNotExistsOauthPendingNodeList);
    const oauthPendingForIbCreationList  = totalNodeList.concat(mandateNotExistsOauthPendingForIbCreationNodeList);
    const portfolioNotExistsPendingList = totalNodeList.concat(consentandOauthPendingForNoPortfolioNodeList);
    const portfolioNotExistsPendingAndIbCreationList = totalNodeList.concat(consentandOauthPendingForNoPortfolioAndIbCreationNodeList);
    const portfolioNotExistsConsentPendingList = totalNodeList.concat(consentPendingForNoPortfolioNodeList);
    const portfolioNotExistsConsentPendingAndIbCreationList = totalNodeList.concat(consentPendingForNoPortfolioAndIbCreationNodeList);
    const portfolioNotExistsOauthPendingList = totalNodeList.concat(oauthPendingForNoPortfolioNodeList);
    const portfolioNotExistsOauthPendingForIbCreationList = totalNodeList.concat(oauthPendingForNoPortfolioAndIbCreationNodeList);
    const portfolioAndMandateNotExists = totalNodeList.concat(MandateAndPortfolioNotExistsNodeList);
    const portfolioAndMandateNotExistsForIbCreation = totalNodeList.concat(MandateAndPortfolioNotExistsForIbCreationNodeList);
    const portfolioExistsAndmandateNotExists = totalNodeList.concat(portfolioUploadMandateNotExistsRebalanceFlowNodeList);
    const portfolioExistsAndmandateNotExistsforIBCreation = totalNodeList.concat(portfolioUploadMandateNotExistsForIBCreationFlowNodeList);
     
    //purchase_pending: true, mandate not exists, portfolio_exists: true
    const mandateNotExistsAndPortfolioExists = totalNodeList.concat(MandateNotExistsFlowNodeList);
    const mandateNotExistsAndPortfolioExistsForIBCreation = totalNodeList.concat(MandateNotExistsForIBCreationFlowNodeList);
    const consentAndOauthPendingforMandateNotExistsList = totalNodeList.concat(mandateNotExistsAndConsentOauthPendingFlowNodeList);
    const consentAndOauthPendingforMandateNotExistsAndIBCreationList = totalNodeList.concat(mandateNotExistsAndConsentOauthPendingForIBCreationFlowNodeList);
    const consentPendingforMandateNotExistsList = totalNodeList.concat(mandateNotExistsAndConsentPendingFlowNodeList);
    const consentPendingforMandateNotExistsAndIbCreationList = totalNodeList.concat(mandateNotExistsAndConsentPendingForIBCreationFlowNodeList);
    const oauthPendingforManadteNotExistsList  = totalNodeList.concat(mandateNotExistsAndOauthPendingFlowNodeList);
    const oauthPendingforManadteNotExistsForIbCreationList  = totalNodeList.concat(mandateNotExistsAndOauthPendingForIbCreationFlowNodeList);

    //purchase_pending: true, mandate exists, portfolio_exists: true
    const mandateAndPortfolioExists = totalNodeList.concat(portfolioUploadFlowNodeList);
    const mandateAndPortfolioExistsForIBCreation = totalNodeList.concat(portfolioUploadForIBCreationFlowNodeList);
    const consentAndOauthPendingforMandateExistsList = totalNodeList.concat(mandateExistsAndConsentOauthPendingFlowNodeList);
    const consentAndOauthPendingforMandateExistsAndIbCreationList = totalNodeList.concat(mandateExistsAndConsentOauthPendingForIBCreationFlowNodeList);
    const consentPendingforMandateExistsList = totalNodeList.concat(mandateExistsAndConsentPendingFlowNodeList);
    const consentPendingforMandateExistsAndIbCreationList = totalNodeList.concat(mandateExistsAndConsentPendingForIBCreationFlowNodeList);
    const oauthPendingforManadteExistsList  = totalNodeList.concat(mandateExistsAndOauthPendingFlowNodeList);
    const oauthPendingforManadteExistsAndIbCreationList  = totalNodeList.concat(mandateExistsAndOauthPendingForIBCreationFlowNodeList);
    
    //purchase_pending: false, mandate exists, portfolio_exists: true
    const consentAndOauthPendingForPortfolioExistsList = totalNodeList.concat(mandateAndConsentOauthPendingFlowNodeList);
    const consentAndOauthPendingForPortfolioExistsAndIbCreationList = totalNodeList.concat(mandateAndConsentOauthPendingForIBCreationFlowNodeList);
    const portfolioExistsConsentPendingList = totalNodeList.concat(mandateAndConsentPendingFlowNodeList);
    const portfolioExistsConsentPendingForIbCreationList = totalNodeList.concat(mandateAndConsentPendingForIbCreationFlowNodeList);
    const portfolioExistsOauthPendingList = totalNodeList.concat(mandateAndOauthPendingFlowNodeList);
    const portfolioExistsOauthPendingForIBCreationList = totalNodeList.concat(mandateAndOauthPendingForIBCreationFlowNodeList);
    const portfolioAndMandateExists = totalNodeList.concat(mandateExistsFlowNodeList);
    const portfolioAndMandateExistsForIBCreation = totalNodeList.concat(mandateExistsForIBCreationFlowNodeList);
    
    //purchase_pending: false, mandate exists, portfolio_exists: false
    const consentAndOauthPendingSkipPortfolioList = totalNodeList.concat(consentOauthPendingAndMandateExistsFlowNodeList);
    const consentAndOauthPendingSkipPortfolioForIbCreationList = totalNodeList.concat(consentOauthPendingAndMandateExistsForIbCreationFlowNodeList);
    const skipPortfolioConsentPendingList = totalNodeList.concat(skipPortfolioAndConsentPendingFlowNodeList);
    const skipPortfolioConsentPendingForIbCreationList = totalNodeList.concat(skipPortfolioAndConsentPendingFlowForIbCreationNodeList);
    const skipPortfolioOauthPendingList = totalNodeList.concat(skipPortfolioAndOauthPendingFlowNodeList);
    const skipPortfolioOauthPendingForIbCreationList = totalNodeList.concat(skipPortfolioAndOauthPendingForIbCreationFlowNodeList);
    const skipPortfolioAndMandateExists = totalNodeList.concat(mandateExistsAndSkipPortfolioFlowNodeList);
    const skipPortfolioAndMandateForIbCreation = totalNodeList.concat(mandateExistsAndSkipPortfolioFlowForIbCreationNodeList);

    // TODO : need to refactor this to use switch-case

    const userNodeFlow = (ecanApproved === false ?  ecanNotApprovedList : 
        mandateExists === false ? purchasePending === true ? (consentPending ? 
            (oauthPending ? (ibFlagStatus === -1 ? consentAndOauthPendingforMandateNotExistsAndIBCreationList : consentAndOauthPendingforMandateNotExistsList) : (ibFlagStatus === -1 ? consentPendingforMandateNotExistsAndIbCreationList : consentPendingforMandateNotExistsList)) 
            : (oauthPending ? (ibFlagStatus === -1 ? oauthPendingforManadteNotExistsForIbCreationList  : oauthPendingforManadteNotExistsList) : (ibFlagStatus === -1 ? mandateNotExistsAndPortfolioExistsForIBCreation : mandateNotExistsAndPortfolioExists))) 
        :  (consentPending ? 
        (oauthPending ? 
            (portfolioAvailable ? (ibFlagStatus === -1 ? consentAndOauthPendingForIbCreationList : consentAndOauthPendingList) : (ibFlagStatus === -1 ? portfolioNotExistsPendingAndIbCreationList : portfolioNotExistsPendingList) ) 
            : (portfolioAvailable ? (ibFlagStatus === -1 ? consentPendingListForIbCreation : consentPendingList) : (ibFlagStatus === -1 ? portfolioNotExistsConsentPendingAndIbCreationList : portfolioNotExistsConsentPendingList) ))

            :
            (oauthPending ? 
                (portfolioAvailable ? (ibFlagStatus === -1 ? oauthPendingForIbCreationList : oauthPendingList) : (ibFlagStatus === -1 ? portfolioNotExistsOauthPendingForIbCreationList : portfolioNotExistsOauthPendingList) ) 
                : (portfolioAvailable ? (ibFlagStatus === -1 ? portfolioExistsAndmandateNotExistsforIBCreation : portfolioExistsAndmandateNotExists) : (ibFlagStatus === -1 ? portfolioAndMandateNotExistsForIbCreation : portfolioAndMandateNotExists) )))
            :  purchasePending === true ? (consentPending ? 
                (oauthPending ? (ibFlagStatus === -1 ? consentAndOauthPendingforMandateExistsAndIbCreationList : consentAndOauthPendingforMandateExistsList ) : (ibFlagStatus === -1 ? consentPendingforMandateExistsAndIbCreationList : consentPendingforMandateExistsList)) 
                : (oauthPending ? ( ibFlagStatus === -1 ? oauthPendingforManadteExistsAndIbCreationList : oauthPendingforManadteExistsList) : (ibFlagStatus === -1 ? mandateAndPortfolioExistsForIBCreation : mandateAndPortfolioExists))) 

            : (consentPending ? 
                (oauthPending ? 
                    (portfolioAvailable ? ( ibFlagStatus === -1 ? consentAndOauthPendingForPortfolioExistsAndIbCreationList  : consentAndOauthPendingForPortfolioExistsList ) : (ibFlagStatus === -1 ? consentAndOauthPendingSkipPortfolioForIbCreationList : consentAndOauthPendingSkipPortfolioList) ) 
                    : (portfolioAvailable ? (ibFlagStatus === -1 ? portfolioExistsConsentPendingForIbCreationList : portfolioExistsConsentPendingList) : (ibFlagStatus === -1 ? skipPortfolioConsentPendingForIbCreationList : skipPortfolioConsentPendingList) ))
                    :
                    (oauthPending ? 
                        (portfolioAvailable ? (ibFlagStatus === -1 ? portfolioExistsOauthPendingForIBCreationList : portfolioExistsOauthPendingList) : (ibFlagStatus === -1 ?  skipPortfolioOauthPendingForIbCreationList : skipPortfolioOauthPendingList) ) 
                        : (portfolioAvailable ? (ibFlagStatus === -1 ? portfolioAndMandateExistsForIBCreation : portfolioAndMandateExists) : (ibFlagStatus === -1 ? skipPortfolioAndMandateForIbCreation : skipPortfolioAndMandateExists) ))));
    const breakpointIdx =
      userNodeFlow.indexOf(messageBreakpoint) !== -1
        ? userNodeFlow.indexOf(messageBreakpoint)
        : userNodeFlow.length;
    if(breakpointIdx === -1){
        bugAlert({
            triggerNode:'breakpoint',
            message:` MessageBreakpoint is : ${messageBreakpoint} with index of ${breakpointIdx}` 
        })
    }
    userNodeFlow.forEach((x, index) => {
        if(index <= breakpointIdx){
            if(startsWith(x, 'client-')) {
                userMessages.push({user: 'client', message: capitalize(x.substring(x.indexOf('-')+1)), options: []})
            } else {
                if (verificationHook !== "ecan_cancelled" && x === 'ecanCancelledDetails') {

                }
                else {
                    userMessages.push(chatContents[x]);
                }
            }
        }
    })
    return userMessages;
}