import React, { useEffect, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import './App.scss';
import './fonts/MyFontsWebfontsKit.css';
import 'bootstrap/dist/css/bootstrap.css';
import { history } from './History';
import { initGA, PageView } from './utils/tracking';
import AppLoader from './components/appLoader/appLoader';


const About = lazy(() =>
  import('./components/About/About' /* webpackChunkName: "about" */)
);
const Layout = lazy(() =>
  import('./components/Layout/Layout' /* webpackChunkName: "layout" */)
);

const Disclaimer = lazy(() =>
  import(
    './components/Disclaimer/Disclaimer' /* webpackChunkName: "disclaimer" */
  )
);
const TermsAndConditions = lazy(() =>
  import(
    './components/TermsAndConditions/TermsAndConditions' /* webpackChunkName: "TC" */
  )
);
const RefundPolicy = lazy(() =>
  import(
    './components/RefundPolicy/RefundPolicy' /* webpackChunkName: "Refund policy" */
  )
);
const PrivacyPolicy = lazy(() =>
  import(
    './components/PrivacyPolicy/PrivacyPolicy' /* webpackChunkName: "Privacy policy" */
  )
);




function App() {
  useEffect(() => {
    initGA();
    PageView();
  }, []);

  return (
    <ParallaxProvider>
      <Provider store={store}>
        <Router history={history}>
          <div className='App'>
            <Suspense fallback={<AppLoader />}>
              <Layout>
                <Switch>
                  <Route path='/' exact component={About} />
               
                
                  {/*<Route
                    path='/user_verification'
                    component={UserVerification}
                  />*/}
              
                  {/*<Route path='/explore' component={Graph} />*/}
                  <Route path='/disclaimer' component={Disclaimer} />
                  {/*<Route path='/digilocker-test' component={Test} />*/}
                  <Route
                    path='/terms-and-conditions'
                    component={TermsAndConditions}
                  />
                  <Route path='/refund-policy' component={RefundPolicy} />
                  <Route path='/privacy-policy' component={PrivacyPolicy} />
                  {/*<Route path='/asset-allocation' component={AssetAllocation} />*/}
                  {/*<Route path='/customer-support' component={CustomerSupport} />*/}
                </Switch>
              </Layout>
            </Suspense>
          </div>
        </Router>
      </Provider>
    </ParallaxProvider>
  );
}

export default App;
